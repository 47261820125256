import { PureComponent } from 'react';
import QRCode from 'qrcode.react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import request from '../../api/request';
import StringField from '../../components/form/StringField';

import Button from '../../components/common/Button';

const messages = defineMessages({
  DESCRIPTION: {
    id: 'MFAForm.DESCRIPTION',
    defaultMessage:
      'Scan the QR code with the Authenticator app of your choice (e.g. 2FAS Authenticator) and then enter a generated code by the app',
  },
  EXISTS: {
    id: 'MFAForm.EXISTS',
    defaultMessage:
      'The two-factor authentication of your user is active. An Authenticator passcode is required for deactivation',
  },
  KEY_TEXT: {
    id: 'MFAForm.KEY_TEXT',
    defaultMessage: 'Alternatively, you can enter the following key in the app:',
  },
  SAVING: {
    id: 'WidgetForm.SAVING',
    defaultMessage: 'Saving...',
  },
  CANCEL: {
    id: 'DeleteForm.CANCEL',
    defaultMessage: '✖ Cancel',
  },
  ACTIVATE: {
    id: 'MFAForm.ACTIVATE',
    defaultMessage: 'Activate',
  },
  DISABLE: {
    id: 'MFAForm.DISABLE',
    defaultMessage: 'Disable',
  },
  MFA_PASSCODE: {
    id: 'MFAForm.MFA_PASSCODE',
    defaultMessage: 'MFA Passcode',
  },
});

let globalformatMessage;
const required = (value) =>
  value
    ? undefined
    : globalformatMessage &&
      globalformatMessage({ id: 'Validator.MANDATORY_FIELD', defaultMessage: 'Mandatory Field' });

class MFAFormComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { mfa_url: null, mfa_enabled: props.user.mfa_enabled, mfa_token: null };
  }

  componentDidMount() {
    if (!this.props.user.mfa_enabled) {
      request.get({ url: '/mfa' }).then(
        (result) => {
          if (result) {
            this.setState({ mfa_url: result.url, mfa_token: result.token });
            this.props.change('mfa_token', result.token);
          }
        },
        (error) => {
          error && error._status === 409 && this.setState({ mfa_enabled: true });
        }
      );
    } else {
      this.props.change('mfa_enabled', true);
    }
  }

  render() {
    const {
      handleSubmit,
      error,
      submitting,
      onCancel,
      intl: { formatMessage },
      size = 300,
    } = this.props;

    const { mfa_enabled, mfa_url, mfa_token } = this.state;

    if (formatMessage) {
      globalformatMessage = formatMessage;
    }

    return (
      <form onSubmit={handleSubmit}>
        {mfa_enabled ? (
          <FormattedMessage {...messages.EXISTS} />
        ) : (
          <FormattedMessage {...messages.DESCRIPTION} />
        )}

        {/* Show QR Code */}
        <div className="pa2">
          {mfa_url && (
            <a href={mfa_url}>
              <QRCode size={size} value={mfa_url} />
            </a>
          )}
        </div>

        {!mfa_enabled && (
          <div className="pb2">
            <FormattedMessage {...messages.KEY_TEXT} values={{ key: mfa_token }} />
          </div>
        )}

        {/* MFA passcode */}
        {
          <Field
            name="mfa_passcode"
            component={StringField}
            validate={required}
            inputmode="numeric"
            config={{
              label: formatMessage(messages.MFA_PASSCODE),
              autoComplete: 'one-time-code',
              inputMode: 'numeric',
              maxLength: 6,
              pattern: '[0-9]*',
            }}
            // no_shadow
          />
        }
        {error ? <p className="ssf-red">{error}</p> : null}
        {submitting ? <p className="info tc">{formatMessage(messages.SAVING)}</p> : null}
        <div className="tr">
          {!mfa_enabled && (
            <Button
              type="button"
              disabled={submitting}
              onClick={onCancel}
              label={formatMessage(messages.CANCEL)}
              marginRight
            />
          )}
          {mfa_enabled ? (
            <Button
              activestyle="danger"
              flat
              type="submit"
              disabled={submitting}
              label={formatMessage(messages.DISABLE)}
            />
          ) : (
            <Button
              activestyle="primary"
              flat
              type="submit"
              disabled={submitting}
              label={formatMessage(messages.ACTIVATE)}
            />
          )}
        </div>
      </form>
    );
  }
}

export default reduxForm({ form: 'mfa' })(injectIntl(MFAFormComponent));
