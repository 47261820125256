import { createAction } from 'redux-actions';
import { addLocaleData } from 'react-intl';

import localeDataEn from 'react-intl/locale-data/en';
import localeDataDe from 'react-intl/locale-data/de';
import localeDataEs from 'react-intl/locale-data/es';
import localeDataIt from 'react-intl/locale-data/it';
import localeDataVi from 'react-intl/locale-data/vi';

import messagesEn from '../lang/messagesEn.json';
import messagesDe from '../lang/messagesDe.json';
import messagesEs from '../lang/messagesEs.json';
import messagesIt from '../lang/messagesIt.json';
import messagesVi from '../lang/messagesVi.json';

import { LANGUAGE_CHANGED, TIMEZONE_CHANGED } from './constants';

addLocaleData(localeDataEn);
addLocaleData(localeDataDe);
addLocaleData(localeDataEs);
addLocaleData(localeDataIt);
addLocaleData(localeDataVi);

const MESSAGES = {
  en: messagesEn,
  de: messagesDe,
  es: messagesEs,
  it: messagesIt,
  vi: messagesVi,
};

// eslint-disable-next-line import/prefer-default-export
export const languageChanged = createAction(LANGUAGE_CHANGED, (locale) => ({
  locale,
  // key: locale, // HACK force UI to update after language was changed
  messages: MESSAGES[locale] ? MESSAGES[locale] : MESSAGES.en,
}));

export const timezoneChanged = createAction(TIMEZONE_CHANGED, (timeZone) => ({
  timeZone,
  // key: timeZone, // HACK force UI to update after language was changed
}));
