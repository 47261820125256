import datemathParser from 'datemath-parser';

export default function dateMath(expr, tz = undefined) {
  if (expr === undefined) {
    return null;
  }

  if (expr === null) {
    return null;
  }

  if (tz) {
    try {
      return datemathParser.parse(expr, undefined, false, tz, true);
    } catch (error) {
      return null;
    }
  }

  try {
    return datemathParser.parse(expr);
  } catch (error) {
    return null;
  }
}
