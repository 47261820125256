// import './wdyr';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import IntlProvider from './components/common/IntlProvider';
import ApplicationPage from './pages/ApplicationPage';
import configureStore from './store';

import 'tachyons/css/tachyons.css';
import '@fortawesome/fontawesome-free/css/all.css';
// import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
// import 'react-virtualized-select/styles.css';
import './styles/styles.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-responsive-modal/styles.css';
import 'primereact/resources/themes/saga-blue/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import 'primeflex/primeflex.min.css';
import * as serviceWorker from './serviceWorker';

// import store from './store';

const store = configureStore();

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <IntlProvider>
      <ApplicationPage />
    </IntlProvider>
    <ToastContainer />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
