import { defineMessages, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import TextField from '../../../components/form/TextField';
import Button from '../../../components/common/Button';

const messages = defineMessages({
  LABEL_PASSWORD1: {
    id: 'SetPasswordForm.LABEL_PASSWORD1',
    defaultMessage: 'New Password',
  },
  LABEL_PASSWORD2: {
    id: 'SetPasswordForm.LABEL_PASSWORD2',
    defaultMessage: 'Confirm Password',
  },
  CHECKING_DATA: {
    id: 'SetPasswordForm.CHECKING_DATA',
    defaultMessage: 'Checking data...',
  },
  CHANGE_PASSWORD: {
    id: 'SetPasswordForm.CHANGE_PASSWORD',
    defaultMessage: 'Change my password',
  },
  ERRORS_PASSWORD1: {
    id: 'SetPasswordForm.ERRORS_PASSWORD1',
    defaultMessage: 'New password error',
  },
  ERRORS_PASSWORD2: {
    id: 'SetPasswordForm.ERRORS_PASSWORD2',
    defaultMessage: 'Confirm Password error',
  },
  ERRORS_PASSWORDS: {
    id: 'SetPasswordForm.ERRORS_PASSWORDS',
    defaultMessage: "Passwords don't match",
  },
});

const SetPasswordForm = ({ handleSubmit, error, submitting, intl: { formatMessage } }) => (
  <form onSubmit={handleSubmit}>
    <Field
      id="inputPassword"
      className="pa2 f4"
      name="password"
      component={TextField}
      type="password"
      autoFocus
      label={formatMessage(messages.LABEL_PASSWORD1)}
      autoComplete="new-password"
      no_shadow
    />
    <Field
      id="inputPasswordConfirm"
      className="pa2 f4"
      name="password_confirm"
      component={TextField}
      type="password"
      label={formatMessage(messages.LABEL_PASSWORD2)}
      autoComplete="new-password"
      no_shadow
    />
    {error ? <p className="dark-red">{error}</p> : null}
    {submitting ? <p className="light-blue tc">{formatMessage(messages.CHECKING_DATA)}</p> : null}
    <Button
      large
      activestyle="primary"
      block
      type="submit"
      disabled={submitting}
      label={formatMessage(messages.CHANGE_PASSWORD)}
    />
  </form>
);

function validatevalidateSetPasswordForm(data, { intl: { formatMessage } }) {
  const errors = {};
  if (!data.password) {
    errors.password = formatMessage(messages.ERRORS_PASSWORD1);
  }
  if (!data.password_confirm) {
    errors.password_confirm = formatMessage(messages.ERRORS_PASSWORD2);
  }
  if (data.password && data.password_confirm && data.password !== data.password_confirm) {
    errors._error = formatMessage(messages.ERRORS_PASSWORDS);
  }
  return errors;
}

export default injectIntl(
  reduxForm({
    form: 'password_set',
    validate: validatevalidateSetPasswordForm,
    touchOnBlur: false,
  })(SetPasswordForm)
);
