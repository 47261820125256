import { PureComponent } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { defineMessages, injectIntl } from 'react-intl';
import { SubmissionError } from 'redux-form';
import styled from 'styled-components';

import TimeRangeSelectorForm from './TimeRangeSelectorForm';
import TimeRangeSelectorPreview from './TimeRangeSelectorPreview';
import TimeRangeSelectorQuickRanges from './TimeRangeSelectorQuickRanges';

import { dashboardUpdated } from '../../../actions/dashboard';
import Button from '../../../components/common/Button';

import { patchDashboard } from '../../../api/dashboard';
import { withHOCSRouter } from '../../../components/hocs';

const messages = defineMessages({
  HEADER: {
    id: 'TimeRangeSelector.HEADER',
    defaultMessage: 'Please pick a date',
  },
  SAVE: {
    id: 'TimeRangeSelectorForm.SAVE',
    defaultMessage: 'Save',
  },
  CLOSE: {
    id: 'TimeRangeSelector.CLOSE',
    defaultMessage: 'Close',
  },
  DESCRIPTION: {
    id: 'TimeRangeSelector.DESCRIPTION',
    defaultMessage:
      'Here you can modify the time range of the dashboard. All Range-Widgets will be adjusted to display the selected time range.',
  },
  NEW_RANGE: {
    id: 'TimeRangeSelector.NEW_RANGE',
    defaultMessage: 'New time range',
  },
});

Modal.defaultStyles = {
  overlay: null,
  content: null,
};

const ModalBlock = styled(Modal)`
  color: ${({ theme }) => theme.color};
  background: ${({ theme }) => theme.background};
`;
const DisplayedTime = styled.div`
  margin-right: 0.5rem;
  display: flex;
  @media (max-width: 1125px) {
    display: none;
  }
`;
const ButtonTime = styled.div`
  display: flex;
  align-items: center;
`;

class TimeRangeSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
  }

  open() {
    this.setState(() => ({ isOpen: true }));
  }

  close() {
    this.setState(() => ({ isOpen: false }));
  }

  submit(formData) {
    const { user, dashboard, navigate, location } = this.props;

    // don't push to server if user is inside a dashboard template or does not want to save
    if (!formData.permanently_save) {
      navigate(
        `${location.pathname}?${queryString.stringify({ from: formData.from, to: formData.to })}`
      );
      this.close();
      return null;
    }

    let user_id;
    if (user && dashboard && user.id !== dashboard.user_id) {
      // overwrite user_id because admin is editing it
      user_id = dashboard.user_id;
    }

    return patchDashboard(
      dashboard.id,
      {
        date_from: formData.from,
        date_to: formData.to,
        config: { ...dashboard.config, now_from: formData.now_from, now_to: formData.now_to },
      },
      user_id
    ).then(
      (updatedDashboard) => {
        this.props.dashboardUpdated({ data: updatedDashboard });
        navigate(
          `${location.pathname}?${queryString.stringify({ from: formData.from, to: formData.to })}`
        );
        this.close();
      },
      (error) => {
        throw new SubmissionError(error);
      }
    );
  }

  render() {
    const {
      from,
      to,
      dashboard,
      timeValues,
      intl: { formatMessage },
      timezone_offset,
    } = this.props;

    const now_from = (dashboard.config && dashboard.config.now_from) || 'now-30m';
    const now_to = (dashboard.config && dashboard.config.now_to) || 'now+5m';
    return (
      <div className="dib">
        <Button
          activestyle="primary"
          onClick={this.state.isOpen ? this.close : this.open}
          tabIndex="0"
          label={
            <ButtonTime>
              <DisplayedTime>
                <TimeRangeSelectorPreview from={from} to={to} timezone_offset={timezone_offset} />
              </DisplayedTime>
              <i className="far fa-clock" />
            </ButtonTime>
          }
        />
        <ModalBlock
          isOpen={this.state.isOpen}
          // onAfterOpen={afterOpenFn}
          onRequestClose={this.close}
          // closeTimeoutMS={n}
          // style={modalStyle}
          contentLabel="Modal"
          className="ma2"
        >
          <div className="flex-auto mw6 mt5 flex flex-column">
            <div className="flex-auto mt4 mb2 flex justify-between items-center">
              <h2>{formatMessage(messages.HEADER)}</h2>
              <div className="flex items-center mr3">
                <Button
                  onClick={() => this.submit(timeValues)}
                  type="submit"
                  icon="far fa-save"
                  activestyle="primary"
                />
                <div
                  onClick={this.close}
                  className="pointer"
                  title={formatMessage(messages.CLOSE)}
                  style={{
                    fontSize: '18px',
                    paddingLeft: '16px',
                  }}
                >
                  <i className="fa fa-times" />
                </div>
              </div>
            </div>
            <div className="flex-auto flex">
              <div className="flex-auto mr3">
                <div className="mb3">{formatMessage(messages.DESCRIPTION)}</div>
                <div className="mb1">
                  <TimeRangeSelectorQuickRanges />
                </div>
                <TimeRangeSelectorForm
                  initialValues={{ from, to, now_from, now_to }}
                  onSubmit={this.submit}
                  onCancel={this.close}
                  timezone_offset={timezone_offset}
                />
              </div>
            </div>
          </div>
        </ModalBlock>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.search,
    timeValues: state.form.timeRangeSelector ? state.form.timeRangeSelector.values : null,
    timezone_offset: state.auth.user.timezone_offset,
    user: state.auth.user,
  }),
  { dashboardUpdated }
)(withHOCSRouter(injectIntl(TimeRangeSelector)));
