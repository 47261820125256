import { handleActions } from 'redux-actions';
import cloneDeep from 'lodash/cloneDeep';

import {
  LIST_SET,
  LIST_ERROR,
  LIST_PENDING,
  DOCUMENT_DELETED,
  RESOURCE_CREATED,
  RESOURCE_UPDATED,
  RESOURCE_DELETED,
} from '../actions/constants';

export default handleActions(
  {
    // eslint-disable-next-line
    [LIST_SET]: (state, { payload: { id, response } }) => {
      return {
        ...state,
        pending: false,
        [id]: { rows: response.results },
        // [id]: getRowState(state[id], response),
      };
    },

    // eslint-disable-next-line
    [LIST_ERROR]: (state, { payload: { id, error } }) => {
      return {
        ...state,
        [id]: { pending: false, error, rows: [] },
      };
    },
    [LIST_PENDING]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { ...state[id], pending: true },
    }),

    [RESOURCE_CREATED]: (state, { payload: { id, data } }) => {
      if (state[id]) {
        const newState = cloneDeep(state[id]);

        if (data.notifications && data.notifications.length > 0) {
          // add temporary space within the keys (TODO: remove with final-form)
          // eslint-disable-next-line no-param-reassign
          data.notifications = data.notifications.map((notification) => {
            const newNotification = {};
            Object.keys(notification).forEach((key) => {
              if (key.startsWith('+') && key.indexOf(' ') === -1) {
                newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
              } else {
                newNotification[key] = notification[key];
              }
            });
            return newNotification;
          });
        } else if (data.config_notification && data.config_notification.length > 0) {
          // add temporary space within the keys (TODO: remove with final-form)
          // eslint-disable-next-line no-param-reassign
          data.config_notification = data.config_notification.map((notification) => {
            const newNotification = {};
            Object.keys(notification).forEach((key) => {
              if (key.startsWith('+') && key.indexOf(' ') === -1) {
                newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
              } else {
                newNotification[key] = notification[key];
              }
            });
            return newNotification;
          });
        }

        newState.rows.unshift(data);

        return { ...state, [id]: newState };
      }
      return state;
    },

    [RESOURCE_UPDATED]: (state, { payload: { id, data } }) => {
      if (state[id] && state[id].rows) {
        const idx = state[id].rows.findIndex((r) => r.id === data.id);
        if (idx !== -1) {
          const newState = cloneDeep(state[id]);

          if (data.notifications && data.notifications.length > 0) {
            // add temporary space within the keys (TODO: remove with final-form)
            // eslint-disable-next-line no-param-reassign
            data.notifications = data.notifications.map((notification) => {
              const newNotification = {};
              Object.keys(notification).forEach((key) => {
                if (key.startsWith('+') && key.indexOf(' ') === -1) {
                  newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
                } else {
                  newNotification[key] = notification[key];
                }
              });
              return newNotification;
            });
          } else if (data.config_notification && data.config_notification.length > 0) {
            // add temporary space within the keys (TODO: remove with final-form)
            // eslint-disable-next-line no-param-reassign
            data.config_notification = data.config_notification.map((notification) => {
              const newNotification = {};
              Object.keys(notification).forEach((key) => {
                if (key.startsWith('+') && key.indexOf(' ') === -1) {
                  newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
                } else {
                  newNotification[key] = notification[key];
                }
              });
              return newNotification;
            });
          }

          newState.rows[idx] = data;

          return { ...state, [id]: newState };
        }
      }
      return state;
    },

    [RESOURCE_DELETED]: (state, { payload: { id, data } }) => {
      if (state[id] && state[id].rows) {
        const idx = state[id].rows.findIndex((r) =>
          Object.keys(data).every((k) => r[k] === data[k])
        );
        if (idx !== -1) {
          const newState = cloneDeep(state[id]);
          newState.rows.splice(idx, 1);
          return { ...state, [id]: newState };
        }
      }
      return state;
    },

    [DOCUMENT_DELETED]: (state, { payload: { id, datasource_id } }) => {
      const key = `machine-${id}-datasources`;
      if (state[key] && state[key].rows) {
        // eslint-disable-next-line
        const idx = state[key].rows.findIndex((r) => r.datasource_id === datasource_id);
        if (idx !== -1) {
          const newState = { ...state[key], rows: [...state[key].rows] };
          newState.rows[idx] = {
            ...newState.rows[idx],
            document_count: newState.rows[idx].document_count - 1,
          };
          return { ...state, [key]: newState };
        }
      }
      return state;
    },
  },
  {}
);
