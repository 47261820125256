import { PureComponent } from 'react';
import { Field } from 'redux-form';
import { defineMessages, injectIntl } from 'react-intl';
import StringField from '../../components/form/StringField';
import TwoColumns from '../../components/common/TwoColumns';
import FieldWrapper from '../../components/form/FieldWrapper';

const messages = defineMessages({
  ADD_PHONE: {
    id: 'UserPage.ADD_PHONE',
    defaultMessage: 'Add phone',
  },
  REMOVE_PHONE: {
    id: 'UserPage.REMOVE_PHONE',
    defaultMessage: 'Remove phone',
  },
  PHONE_NUMBER: {
    id: 'UserPage.PHONE',
    defaultMessage: 'Phone number',
  },
  TAG: {
    id: 'UserPage.TAG',
    defaultMessage: 'Tag',
  },
});

class PhoneNumbersField extends PureComponent {
  render() {
    const {
      config,
      fields,
      meta,
      intl: { formatMessage },
    } = this.props;

    return (
      <FieldWrapper label={config.label} meta={meta}>
        <ul className="list ph0">
          {fields.map((name, index) => (
            <li key={name} className="mb3">
              <button
                type="button"
                title="Remove"
                onClick={() => fields.remove(index)}
                className="bn pv1 ph1 bn ssf-red bg-transparent hover-bg-light-gray pointer"
              >
                <i className="fa fa-trash-alt mr1" />
                {formatMessage(messages.REMOVE_PHONE)}
              </button>
              <div className="pa2 mb2">
                <TwoColumns
                  firstColumn={
                    <Field
                      name={`${name}.phone_number`}
                      component={StringField}
                      config={{
                        label: formatMessage(messages.PHONE_NUMBER),
                        autoComplete: 'tel',
                        placeholder: 'Format: +49123456789',
                        inputType: 'tel',
                      }}
                    />
                  }
                  secondColumn={
                    <Field
                      name={`${name}.phone_tag`}
                      component={StringField}
                      config={{
                        label: formatMessage(messages.TAG),
                      }}
                    />
                  }
                />
              </div>
            </li>
          ))}
          <li>
            <button
              type="button"
              onClick={() => fields.push()}
              className="bn pv1 ph1 ssf-blue bg-transparent hover-bg-light-gray pointer"
            >
              <i className="fa fa-plus-circle mr1" />
              {formatMessage(messages.ADD_PHONE)}
            </button>
          </li>
        </ul>
      </FieldWrapper>
    );
  }
}

export default injectIntl(PhoneNumbersField);
