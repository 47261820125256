import { PureComponent } from 'react';
// import isEqual from 'lodash/isEqual';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import styled from 'styled-components';
import { fullscreenEnter, fullscreenExit } from '../../../actions/fullscreen';
import Button from '../../../components/common/Button';
import DashboardNavigationBar from './DashboardNavigationBar';
import DashboardUpdateNavigationBar from './DashboardUpdateNavigationBar';
import DashboardFilterComponent from './DashboardFilterComponent';
import { isDESMA, isDESMApremium } from '../../../utils/desma';
import { withHOCSRouter } from '../../../components/hocs';

const CointainingDiv = styled.div`
  min-widght: 300px;
`;

const DashboardButtonsHolder = styled.nav`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

const DashboardName = styled.div`
  margin: 0 0.5rem;
  width: 200px;
  font-size: 0.8rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LeftRightButton = styled.button`
  color: ${({ theme }) => theme.color};
  border-radius: 0.2rem;
  border: none;
  padding: 0.2rem;
  background: ${({ theme }) => theme.button.default.backgroundHover};
  cursor: pointer;
`;

const LeftArrow = styled(ChevronLeft)`
  display: block !important;
  font-size: 1.6rem !important;
  vertical-align: middle;
  color: ${({ theme }) => theme.secondaryDiv.color};
`;

const RightArrow = styled(ChevronRight)`
  display: block !important;
  font-size: 1.6rem !important;
  vertical-align: middle;
  color: ${({ theme }) => theme.secondaryDiv.color};
`;

const messages = defineMessages({
  START_LIVE_DATA: {
    id: 'DashboardButton.START_LIVE_DATA',
    defaultMessage: 'start live data',
  },
  STOP_LIVE_DATA: {
    id: 'DashboardButton.STOP_LIVE_DATA',
    defaultMessage: 'stop live data',
  },
  FULLSCREEN_OFF: {
    id: 'DashboardButton.FULLSCREEN_OFF',
    defaultMessage: 'Disable fullscreen',
  },
  FULLSCREEN_ON: {
    id: 'DashboardButton.FULLSCREEN_ON',
    defaultMessage: 'Enable fullscreen',
  },
});

class DashboardButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      showMore: false,
      isUpdate: this.props.location.pathname.includes('updateWidgets'),
      isFullscreen: props.isFullscreen,
    };
    this.setIsFullscreen = this.setIsFullscreen.bind(this);
  }

  setIsFullscreen() {
    const { fullscreenOn, fullscreenOff } = this.props;
    if (this.state.isFullscreen) {
      fullscreenOff();
    } else {
      fullscreenOn();
    }
    this.setState({
      isFullscreen: !this.state.isFullscreen,
    });

    this.forceUpdate();
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ isUpdate: nextProps.location.pathname.includes('updateWidgets') });
  }

  // shouldComponentUpdate(nextProps) {
  //   if (nextProps.location.pathname !== this.props.location.pathname) {
  //     return true;
  //   }
  //   if (!isEqual(nextProps.dashboard, this.props.dashboard)) {
  //     return true;
  //   }
  //   if (!isEqual(nextProps.dashboardList, this.props.dashboardList)) {
  //     return true;
  //   }
  //   return false;
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  showMoreOptions = () => {
    this.setState({ showMore: !this.state.showMore });
    this.forceUpdate();
  };

  setUpdateMode = (mode) => {
    this.setState({ isUpdate: mode });
  };

  changePage(value) {
    const { dashboard, navigate, dashboardList } = this.props;

    if (!dashboard.id) {
      return;
    }

    const dashboardKeys = Object.keys(dashboardList);
    const currentID = dashboard.id;
    const currentIndex = dashboardKeys.indexOf(currentID);
    const nextPageIndex = currentIndex + value;

    let nextPageID;
    let nextDashboard;

    if (currentIndex === 0 && value === -1) {
      nextPageID = dashboardKeys[dashboardKeys.length - 1];
      nextDashboard = dashboardList[nextPageID];
    } else if (currentIndex === dashboardKeys.length - 1 && value === 1) {
      nextPageID = dashboardKeys[0];
      nextDashboard = dashboardList[nextPageID];
    } else {
      nextPageID = dashboardKeys[nextPageIndex];
      nextDashboard = dashboardList[nextPageID];
    }

    if (nextPageID && nextDashboard) {
      // next dashboard (TODO: settings to keep query search):
      // history.push(`/dashboard/${nextPageID}/${location.search}`);
      navigate(`/dashboard/${nextPageID}/`);
    }
  }

  render() {
    const {
      location,
      navigate,
      dashboard,
      setRealtime,
      isRealtime,
      is_desma = false,
      is_desma_premium = false,
      user,
      intl: { formatMessage },
    } = this.props;

    const { windowWidth, showMore, isUpdate, isFullscreen } = this.state;

    const name = dashboard.name || null;

    let show_edit = true;
    if (is_desma && !is_desma_premium) {
      show_edit = false;
    }
    // use show_edit for dashboard sharing

    if (!name) {
      return null;
    }

    let isExternalUser = false;
    if (dashboard && user && dashboard.user_id === user.id) {
      isExternalUser = true;
    }
    const show_filter_button = dashboard.config && dashboard.config.show_filter_button;
    const show_realtime_stop_button =
      dashboard.config && dashboard.config.show_realtime_stop_button;

    return (
      <>
        <DashboardButtonsHolder>
          <LeftRightButton onClick={() => this.changePage(-1)} type="button">
            <LeftArrow />
          </LeftRightButton>
          <DashboardName className="truncate">
            {name}
            {dashboard.external_user_name ? (
              <>
                <br />
                <small className="light-red">{dashboard.external_user_name}</small>
              </>
            ) : null}
          </DashboardName>

          <LeftRightButton onClick={() => this.changePage(1)} type="button">
            <RightArrow />
          </LeftRightButton>
        </DashboardButtonsHolder>
        {isUpdate ? (
          <DashboardUpdateNavigationBar
            currentDashboard={dashboard}
            windowWidth={windowWidth}
            showMore={showMore}
            location={location}
            showMoreOptions={this.showMoreOptions}
            setUpdateMode={this.setUpdateMode}
            show_edit={show_edit}
            isExternalUser={isExternalUser}
          />
        ) : (
          <CointainingDiv>
            <DashboardNavigationBar
              dashboard={dashboard}
              navigate={navigate}
              location={location}
              setUpdateMode={this.setUpdateMode}
              show_edit={show_edit}
            />
          </CointainingDiv>
        )}
        {windowWidth >= 749 && show_realtime_stop_button && (
          <Button
            title={
              isRealtime
                ? formatMessage(messages.STOP_LIVE_DATA)
                : formatMessage(messages.START_LIVE_DATA)
            }
            icon={isRealtime ? 'fa fa-stop' : 'fa fa-play'}
            onClick={() => {
              setRealtime();
              this.forceUpdate();
            }}
            activestyle={isRealtime ? 'primary' : 'danger'}
            marginLeft
          />
        )}
        {windowWidth >= 749 && show_edit && show_filter_button && (
          <DashboardFilterComponent dashboard={dashboard} />
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        {isFullscreen && windowWidth >= 749 ? (
          <Button
            className="mr1"
            activestyle="primary"
            title={formatMessage(messages.FULLSCREEN_OFF)}
            icon="fa fa-compress-alt"
            onClick={() => {
              // fullscreenOff();
              this.setIsFullscreen();
            }}
            marginLeft
          />
        ) : windowWidth >= 749 ? (
          <Button
            className="mr1"
            activestyle="primary"
            title={formatMessage(messages.FULLSCREEN_ON)}
            icon="fa fa-expand-alt"
            onClick={() => {
              // fullscreenOn();
              this.setIsFullscreen();
            }}
            marginLeft
          />
        ) : null}
      </>
    );
  }
}

export default connect(
  (state) => ({
    dashboardList: state.dashboards,
    is_desma: isDESMA(state.config),
    is_desma_premium: isDESMApremium(state.config),
    user: state.auth.user,
    isFullscreen: state.fullscreen,
  }),
  (dispatch) => ({
    fullscreenOn: () => dispatch(fullscreenEnter()),
    fullscreenOff: () => dispatch(fullscreenExit()),
  })
)(withHOCSRouter(injectIntl(DashboardButtons)));
