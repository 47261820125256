import { PureComponent } from 'react';
import { injectIntl } from 'react-intl';

import dateMath from '../../../utils/dateMath';

class TimeRangeSelectorPreview extends PureComponent {
  componentDidMount() {
    this.interval = setInterval(() => {
      this.forceUpdate();
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      from,
      to,
      intl: { formatDate, formatTime },
      timezone_offset,
    } = this.props;
    const f = dateMath(from, timezone_offset);
    const t = dateMath(to, timezone_offset) - 1; // minus 1ms because all requests use lt in query

    return from && to ? (
      <span>
        {formatDate(f)} {formatTime(f)} - {formatDate(t)} {formatTime(t)}
      </span>
    ) : null;
  }
}

export default injectIntl(TimeRangeSelectorPreview);
