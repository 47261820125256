import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

export function HeaderComponent(props) {
  const logo_max_height = '140px';
  const logo_max_width = '250px';
  return (
    <header className="pb1">
      <Link to="/">
        {props.logo ? (
          <img
            src={props.logo}
            alt="logo"
            role="presentation"
            style={{ maxWidth: logo_max_width, maxHeight: logo_max_height }}
          />
        ) : null}
      </Link>
    </header>
  );
}

export default connect((state) => ({
  logo: state.config.data ? `${state.config.data.logo}` : null,
}))(HeaderComponent);
