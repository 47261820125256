import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import { CONFIG_SET, CONFIG_ERROR } from '../actions/constants';

const loading = handleActions(
  {
    [CONFIG_SET]: () => false,
    [CONFIG_ERROR]: () => false,
  },
  true
);

const error = handleActions(
  {
    [CONFIG_SET]: () => null,
    [CONFIG_ERROR]: (state, action) => action.payload,
  },
  null
);

const data = handleActions(
  {
    [CONFIG_SET]: (state, action) => action.payload,
    [CONFIG_ERROR]: () => null,
  },
  null
);

export default combineReducers({
  loading,
  error,
  data,
});
