import request from './request';

export function getEntities() {
  return request.get({ url: '/entity/' });
}
export function createEntity(data, query) {
  return request.post({ url: '/entity/', data, query });
}
export function updateEntity(id, data, query) {
  return request.put({ url: `/entity/${id}/`, data, query });
}
export function deleteEntity(id, query) {
  return request.del({ url: `/entity/${id}/`, query });
}
