import { connect } from 'react-redux';

import classnames from 'classnames';
import DatePicker, { registerLocale, getDefaultLocale, setDefaultLocale } from 'react-datepicker';
import { de, enGB } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

import FieldWrapper from './FieldWrapper';

registerLocale('de', de);
registerLocale('en', enGB);

function DatetimeComponent(props) {
  const { config, meta, input, locale } = props;
  const hasError = meta.touched && meta.error;

  const className = classnames('input-reset bn w-100 pa2', {
    'b--ssf-red': hasError,
  });

  if (getDefaultLocale() !== locale) {
    setDefaultLocale(locale);
  }

  return (
    <FieldWrapper className="ma1" name={input.name} label={config.label} meta={meta}>
      <DatePicker
        id={`${input.name}-id`}
        className={className}
        selected={
          config && config.valuePreprocess
            ? config && config.valuePreprocess(input.value)
            : input.value
        }
        onChange={(date) => {
          Array.isArray(date)
            ? input.onChange(date[0].toISOString())
            : input.onChange(date.toISOString());
        }}
        timeInputLabel={config.label_time}
        dateFormat="Pp"
        timeFormat="p"
        showTimeInput
        autoComplete={config.autoComplete}
        todayButton={config.label_today}
        shouldCloseOnSelect={false}
      />
    </FieldWrapper>
  );
}

export default connect((state) => ({ locale: state.intl.locale }))(DatetimeComponent);
