import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import { INITIAL_DATA_CLEAR, INITIAL_DATA_SET, INITIAL_DATA_ERROR } from '../actions/constants';

const loadingReducer = handleActions(
  {
    [INITIAL_DATA_CLEAR]: () => true,
    [INITIAL_DATA_SET]: () => false,
    [INITIAL_DATA_ERROR]: () => false,
  },
  true
);

const errorReducer = handleActions(
  {
    [INITIAL_DATA_CLEAR]: () => null,
    [INITIAL_DATA_SET]: () => null,
    [INITIAL_DATA_ERROR]: (state, action) => action.payload,
  },
  null
);

export default combineReducers({
  loading: loadingReducer,
  error: errorReducer,
});
