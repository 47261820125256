import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { config } from '../../api/auth';
import { configSet, configError } from '../../actions/auth';

import Loading from '../Loading';
import Error from '../Error';

class ConfigLoader extends PureComponent {
  componentDidMount() {
    if (this.props.loading) {
      this.props.loadConfig();
    }
  }

  render() {
    const { loading, error, data, render } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Error error={error} />;
    }

    return render(data);
  }
}

export default connect(
  (state) => state.config,
  (dispatch) => ({
    loadConfig: () =>
      config().then(
        (data) => dispatch(configSet(data)),
        (error) => dispatch(configError(error))
      ),
  })
)(ConfigLoader);
