import buildTheme from './buildTheme';

const settings = {
  // Main colors
  color: '#555555',
  background: '#f2f3f5',

  secondaryColor: '#555555',
  secondaryBackground: '#f2f3f5',

  mainPageColor: '#555555',
  mainPageBackground: '#f2f3f5',

  blendingSVG: 'normal',
  dashboardsColor: '#555555',
  dashboardBackground: '#f2f3f5',

  // Header colors
  headerBackground: '#fff',
  headerBorder: '1px solid var(--ssf-gray)',

  widgetColor: '#555555',
  widgetBackground: '#fff',
  widgetBorder: '1px solid var(--ssf-gray1)',
  widgetHover: '#f1f1f1',

  // divs colors
  divBackground: '#fff',
  divBorder: '1px solid var(--ssf-gray1)',
  divHover: '#f1f1f1',

  secondaryDivBackground: '#fff',
  secondaryDivBorder: '1px solid var(--ssf-gray1)',
  secondaryDivHover: '#f1f1f1',
  secondaryDivColor: '#555555',

  mainPageDivBackground: '#fff',
  mainPageDivBorder: '1px solid var(--ssf-gray1)',
  mainPageDivHover: '#f1f1f1',
  mainPageDivColor: '#555555',

  linkText: '#0092cd',

  proplistNumbers: '#519dd4',

  // Button colors
  buttonText: '#ffffff',

  defaultButtonText: '#555555',
  default: '#ffffff',
  defaultAccent: '#f1f1f1',
  defaultShadow: '0 1px 2px 0 var(--ssf-gray2)',

  loginAccent: '#f1f1f1',

  secondaryButtonText: '#555555',
  secondary: '#ffffff',
  secondaryAccent: '#f1f1f1',
  secondaryShadow: '0 1px 2px 0 var(--ssf-gray2)',

  primary: '#0092cd',
  primaryAccent: '#2485b4',
  primaryShadow: '0 1px 2px 0 #2485b4',

  danger: '#e84c4d',
  dangerAccent: '#d85a56',
  dangerShadow: '0 1px 2px 0 #d85a56',

  success: '#019958',
  successAccent: '#008D5D',
  successShadow: '0 1px 2px 0 #008D5D',

  gray1: '#1B1E20',
  gray2: '#2D3033',
  gray3: '#31373C',
  gray4: '#394147',
  gray5: '#485158',
  gray6: '#4f5b63',

  mainColor: '#555555',

  // colors for user feedback
  errorIndicator: '#e84c4d',
  successIndicator: '#019958',

  // Img filters
  invertFilter: 'none',

  // Img filters
  mainBoxshadow: 'none',
  lightBoxshadow: '0 2px 5px 0 var(--ssf-gray)',
  mainPageBoxshadow: 'none',

  // Error related
  backgroundErrorColor: '#ffbebf',
  backgroundInfoColor: 'transparent',
  backgroundInfoNonTransparentColor: '#ffffff',
  errorColor: '#e54c4c',
  infoColor: '#3763a2',
  borderErrorColor: '1px solid #e54c4c',
  borderInfoColor: '0px solid #3763a2',
  mailErrorColor: '#e54c4c',
  mailInfoColor: '#3763a2',
};

export default buildTheme(settings);
