import { defineMessages, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import TextField from '../../../components/form/TextField';
import Button from '../../../components/common/Button';

const messages = defineMessages({
  LABEL_EMAIL: {
    id: 'PasswordResetForm.LABEL_EMAIL',
    defaultMessage: 'Email address',
  },
  CHECKING_DATA: {
    id: 'PasswordResetForm.CHECKING_DATA',
    defaultMessage: 'Checking data...',
  },
  RESET_PASSWORD: {
    id: 'PasswordResetForm.RESET_PASSWORD',
    defaultMessage: 'Reset my password',
  },
  ERRORS_EMAIL: {
    id: 'PasswordResetForm.ERRORS_EMAIL',
    defaultMessage: 'Please set your email',
  },
});
const MessageContainer = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  height: 3rem;
  & > p {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const ErrorText = styled.p`
  color: #d7316c;
  width: 100%;
  text-align: center;
`;
const LoadingText = styled(ErrorText)`
  color: #3893c6;
`;
const Form = styled.form`
  min-width: 22rem;
`;
const FieldBlock = styled(Field)`
  height: 2.5rem;
  background-color: ${({ theme }) => theme.loginField.backgroundColor};
  color: ${({ theme }) => theme.loginField.color};
  border: ${({ theme }) => theme.loginField.border};
`;

const PasswordResetForm = ({
  isSecondary,
  handleSubmit,
  error,
  submitting,
  intl: { formatMessage },
}) => (
  <Form onSubmit={handleSubmit}>
    <FieldBlock
      isSecondary={isSecondary}
      id="inputEmail"
      className="pa2 f4"
      name="email"
      component={TextField}
      type="text"
      autoFocus
      label={formatMessage(messages.LABEL_EMAIL)}
      autoComplete="email"
    />
    <MessageContainer>
      {error ? <ErrorText>{error}</ErrorText> : null}
      {submitting ? <LoadingText>{formatMessage(messages.CHECKING_DATA)}</LoadingText> : null}
    </MessageContainer>
    {/* {error ? <p className="dark-red">{error}</p> : null}
    {submitting ? <p className="light-blue tc">{formatMessage(messages.CHECKING_DATA)}</p> : null} */}
    <Button
      large
      activestyle="primary"
      block
      type="submit"
      disabled={submitting}
      label={formatMessage(messages.RESET_PASSWORD)}
    />
  </Form>
);

function validatePasswordResetForm(data, { intl: { formatMessage } }) {
  const errors = {};
  if (!data.email) {
    errors.email = formatMessage(messages.ERRORS_EMAIL);
  }
  return errors;
}

export default injectIntl(
  reduxForm({
    form: 'password_reset',
    validate: validatePasswordResetForm,
    touchOnBlur: false,
  })(PasswordResetForm)
);
