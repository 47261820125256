import { injectIntl, defineMessages } from 'react-intl';
import queryString from 'query-string';
import styled from 'styled-components';
import Button from '../../../components/common/Button';

const messages = defineMessages({
  REALTIME: {
    id: 'TimeRangeShiftCurrent.REALTIME',
    defaultMessage: 'Realtime',
  },
});

function navigate2({ navigate, location, query }) {
  navigate(`${location.pathname}?${queryString.stringify(query)}`);
}

const TimeCurrent = styled.div`
  @media (max-width: 750px) {
    display: none;
  }
`;
const ButtonRealtime = styled(Button)`
  @media (max-width: 1125px) {
    & > i {
      margin: 0;
    }
    & > span {
      display: none;
    }
  }
`;

function TimeRangeShiftCurrent({
  navigate,
  location,
  now_from = 'now-30m',
  now_to = 'now+5m',
  intl: { formatMessage },
}) {
  return (
    <TimeCurrent>
      <ButtonRealtime
        // label-ns={formatMessage(messages.REALTIME)}
        title={formatMessage(messages.REALTIME)}
        icon="fas fa-redo"
        onClick={() => navigate2({ navigate, location, query: { from: now_from, to: now_to } })}
        marginLeft
      />
    </TimeCurrent>
  );
}

export default injectIntl(TimeRangeShiftCurrent);
