import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Loading from './Loading';
// import FailWhale from './FailWhale';
import NotFound from './NotFound';
import Error from './Error';

class InitialData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // timerStart: performance.now(),
      // toSlow: false,
      // timeout: null,
    };
    // this.isLoadingToSlow = this.isLoadingToSlow.bind(this);
  }

  // componentDidMount() {
  //   if (this.props.loading && !this.state.toSlow) {
  //     this.isLoadingToSlow();
  //   }
  // }

  // componentWillUnmount() {
  //   clearTimeout(this.state.timeout);
  // }

  // isLoadingToSlow() {
  //   if (!this.props.loading || this.state.toSlow) {
  //     return;
  //   }

  //   if (performance.now() - this.state.timerStart > 360 * 1000) {
  //     this.setState({ toSlow: true });
  //   }

  //   this.setState({ timeout: setTimeout(this.isLoadingToSlow, 1 * 1000) });
  // }

  render() {
    const { loading, error, children } = this.props;

    // if (this.state.toSlow) {
    //   return <FailWhale />;
    // }

    if (loading) {
      return <Loading />;
    }

    if (error) {
      if (error._status === 404) {
        return <NotFound pathname={window.location.pathname} />;
      }
      return <Error error={error} />;
    }
    return children;
  }
}

export default connect((state) => state.initialData)(InitialData);
