export default function isValidYAxisConfig(axisConfig) {
  if (!axisConfig) {
    return false;
  }
  if (!axisConfig.aggType) {
    return false;
  }
  if (!axisConfig.property) {
    return false;
  }
  return true;
}
