import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { pick } from 'dot-object';
import { findMachineByDatasource } from '../widget/widgets/utils/selectors';

const Label = styled.label`
  color: ${({ theme }) => theme.color};
`;

const LinkConn = styled(Link)`
  color: ${({ theme }) => theme.secondaryDiv.color};
`;

function BooleanFieldComponent({
  config,
  config: { className = 'mb3' },
  meta,
  input,
  label,
  fieldValue,
  intl: { formatMessage },
  device,
  formValues,
  name_prefix,
}) {
  const hasError = meta.touched && meta.error;

  if (input.value != null && fieldValue) {
    // eslint-disable-next-line no-param-reassign
    input.value = fieldValue.value;
  }

  if (config.sensor_type && config.sensor_type.length) {
    if (formValues && formValues.config) {
      if (!device || !device.data || device.data.sensor_type !== config.sensor_type) {
        return null;
      }
      // check current field of yAxis by parsing input.name with config
      if (config.filter_field && config.filter_field.length) {
        const property_element = pick(name_prefix, formValues);
        if (
          !property_element ||
          !property_element.property ||
          property_element.property !== config.filter_field
        ) {
          return null;
        }
      }
    }
  }

  return (
    <div className={className}>
      <Label
        htmlFor={`${input.name}-id`}
        className={classnames(
          'db mb1 mid-gray f6',
          { 'ssf-red': hasError },
          config.wrapperClassName
        )}
      >
        <input
          className="mr1"
          id={`${input.name}-id`}
          type="checkbox"
          checked={input.value}
          onChange={(event) => input.onChange(!!event.target.checked)}
          disabled={config.disabled}
        />
        {config.link ? (
          <LinkConn to={config.link} target="_blank">
            {(typeof label === 'object' && label !== null ? formatMessage(label) : label) ||
              config.label}
          </LinkConn>
        ) : (
          (typeof label === 'object' && label !== null ? formatMessage(label) : label) ||
          config.label
        )}
        {config.second_line && (
          <>
            <br />
            <div className="pl4 pt2">{config.second_line}</div>
          </>
        )}
      </Label>
      {config.descriptionHtml ? (
        <p
          /* eslint react/no-danger: 0 */
          dangerouslySetInnerHTML={{
            __html: formatMessage(config.descriptionHtml),
          }}
        />
      ) : null}
      {hasError && <div className="db mb1 ssf-red">{meta.error}</div>}
    </div>
  );
}

function getDeviceFromForm(stateDevices, formValues) {
  if (
    formValues &&
    formValues.config &&
    formValues.config.datasource &&
    formValues.config.datasource.length
  ) {
    return findMachineByDatasource(stateDevices, formValues.config.datasource);
  }
  return null;
}

export default connect((state, props) => ({
  // formValues: getFormValues(props.meta.form)(state),
  // return value with calculation
  // test: console.log(props),
  formValues: props.config && props.config.sensor_type && getFormValues(props.meta.form)(state),
  device:
    props.config &&
    props.config.sensor_type &&
    getDeviceFromForm(state.machine || {}, getFormValues(props.meta.form)(state)),
}))(injectIntl(BooleanFieldComponent));
