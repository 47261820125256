import { PureComponent } from 'react';
import { batch, connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';

import { login, config } from '../../api/auth';
import { authLogin, showMfa, configSet, configError } from '../../actions/auth';
import { languageChanged, timezoneChanged } from '../../actions/intl';
import CommonPageContainer from '../../components/common/CommonPageContainer';
import LoginForm from './components/LoginForm';
import { sendMessage } from '../../components/Websocket';

const messages = defineMessages({
  PASSWORD_RESET_LINK: {
    id: 'LoginPage.PASSWORD_RESET_LINK',
    defaultMessage: 'Forgot your password?',
  },
});

class LoginPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPasscode: props.auth.login && props.auth.login.mfa_required,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.login && !isEqual(prevProps.auth.login, this.props.auth.login)) {
      if (this.props.auth.login.mfa_required != null) {
        this.setShowPasscode(this.props.auth.login.mfa_required);
      }
    }
  }

  setShowPasscode(val) {
    this.setState({ showPasscode: val });
  }

  render() {
    const {
      onSubmit,
      intl: { formatMessage },
    } = this.props;

    return (
      <CommonPageContainer isSecondary isLogin>
        <LoginForm isSecondary onSubmit={onSubmit} showPasscode={this.state.showPasscode} />
        <Link
          className="self-center mt3 db tc ssf-blue link f7 underline-hover"
          to="/password_reset/"
        >
          {formatMessage(messages.PASSWORD_RESET_LINK)}
        </Link>
      </CommonPageContainer>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  (dispatch, { setLoggedIn }) => ({
    onSubmit: (data) =>
      login(data).then(
        (user) => {
          if (user && user.header && user.header['x-request-mfa']) {
            dispatch(showMfa({ mfa_required: true }));
            return false;
          }

          if (user && user.header && user.header['x-authorization-token']) {
            localStorage.setItem('access_token', user.header['x-authorization-token']);
            sendMessage('Bearer ' + user.header['x-authorization-token']);
          }
          if (user && user.header && user.header['x-refresh-token']) {
            localStorage.setItem('refresh_token', user.header['x-refresh-token']);
          }
          batch(() => {
            dispatch(authLogin(user.body));
            config().then(
              (configData) => dispatch(configSet(configData)),
              (error) => dispatch(configError(error))
            );

            if (user.body.language) {
              dispatch(languageChanged(user.body.language));
            }
            if (user.body.timezone) {
              dispatch(timezoneChanged(user.body.timezone));
            }
          });
          if (user.body.password_info) {
            toast.warn(user.body.password_info, { autoClose: false, theme: 'colored' });
          }
          // info for AuthLoader to start token timers
          setLoggedIn(true);
          return false;
        },
        (error) => {
          throw new SubmissionError(error);
        }
      ),
  })
)(injectIntl(LoginPage));
