import { injectIntl } from 'react-intl';

import styled from 'styled-components';

import FieldWrapper from './FieldWrapper';
import StyledSelect from '../../controls/StyledSelect';
import { getValue } from '../../utils/selectinput';

const Wrapper = styled(FieldWrapper)`
  background: ${({ theme }) => theme.inputField.background};
  padding: 0.3rem;
  box-shadow: ${({ theme }) => theme.div.boxShadow} !important;
  & > label {
    color: ${({ theme }) => theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
  & .Select-menu-outer > .Select-menu > .is-selected {
    background: ${({ theme }) => theme.div.backgroundHover};
  }
  & .Select-menu-outer > .Select-menu > .Select-option:hover {
    background: ${({ theme }) => theme.div.backgroundHover};
  }
`;

const WrapperNoShadow = styled(FieldWrapper)`
  background: ${({ theme }) => theme.inputField.background};
  padding: 0.3rem;
  & > label {
    color: ${({ theme }) => theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
  & .Select-menu-outer > .Select-menu > .is-selected {
    background: ${({ theme }) => theme.div.backgroundHover};
  }
  & .Select-menu-outer > .Select-menu > .Select-option:hover {
    background: ${({ theme }) => theme.div.backgroundHover};
  }
`;

function getOptions(options, formatMessage) {
  return options.map((option) => ({
    value: option.value,
    label: typeof option.label === 'object' ? formatMessage(option.label) : option.label,
  }));
}

function SelectFieldDefault(props) {
  const {
    input,
    meta,
    config: {
      label,
      options,
      placeholder,
      label_not_found,
      isClearable = true,
      isDisabled = false,
      defaultValue = null,
    },
    fieldValue,
    intl: { formatMessage },
    no_shadow = false,
    // theme: ssfTheme,
  } = props;

  if (defaultValue != null && !input.value && !fieldValue) {
    input.value = defaultValue;
  } else if (!input.value && fieldValue) {
    input.value = fieldValue.value;
  }

  const selectOptions = getOptions(options, formatMessage);
  if (no_shadow) {
    return (
      <WrapperNoShadow name={input.name} label={label} meta={meta}>
        <StyledSelect
          {...input}
          value={getValue(input.value, selectOptions)}
          classNamePrefix="Select-control"
          options={selectOptions}
          onChange={(option) => {
            input.onChange(option && option.value ? option.value : null);
          }}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onFocus={() => input.onFocus()}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
          // inputProps={{ id: `${input.name}-id` }}
          isClearable={isClearable}
          noOptionsMessage={() => label_not_found}
          isDisabled={isDisabled}
        />
      </WrapperNoShadow>
    );
  }

  return (
    <Wrapper name={input.name} label={label} meta={meta}>
      <StyledSelect
        {...input}
        value={getValue(input.value, selectOptions)}
        classNamePrefix="Select-control"
        options={selectOptions}
        onChange={(option) => {
          input.onChange(option && option.value ? option.value : null);
        }}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        onFocus={() => input.onFocus()}
        onBlur={() => input.onBlur()}
        placeholder={placeholder}
        // inputProps={{ id: `${input.name}-id` }}
        isClearable={isClearable}
        noOptionsMessage={() => label_not_found}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
}

export default injectIntl(SelectFieldDefault);
