import { PureComponent } from 'react';
import throttle from 'lodash/throttle';
import request from '../api/request';

import Loading from './Loading';
import Error from './Error';

export default class Fetch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      result: null,
    };
    this.fetch = throttle(this.fetch.bind(this), 100);
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.url !== prevProps.url ||
      this.props.method !== prevProps.method ||
      JSON.stringify(prevProps.query) !== JSON.stringify(this.props.query) ||
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)
    ) {
      this.cancel();
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.cancel();
  }

  async fetch() {
    try {
      const result = await new Promise((resolve, reject) => {
        this.apiRequest = request.pureFetch(
          {
            url: this.props.url,
            method: this.props.method,
            query: this.props.query,
            data: this.props.config,
          },
          resolve,
          reject
        );
      });
      this.setState(() => ({
        loading: false,
        error: null,
        result,
      }));
    } catch (error) {
      this.setState(() => ({
        loading: false,
        error,
        result: null,
      }));
    } finally {
      delete this.apiRequest;
    }
  }

  cancel() {
    if (this.apiRequest) {
      this.apiRequest.abort();
    }
  }

  render() {
    if (this.state.loading && !this.state.result) {
      return <Loading />;
    }

    if (this.state.error && this.props.renderError) {
      return this.props.renderError(this.state.error);
    }

    if (this.state.error) {
      // eslint-disable-next-line
      return <Error error={this.state.error._error.slice(0, 100)} />;
    }

    if (!this.state.result) {
      return null;
    }

    return this.props.children(this.state.result);
  }
}
