import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  SHOW_MFA,
  PROFILE_UPDATED,
} from '../actions/constants';

const loading = handleActions(
  {
    [AUTH_LOGIN]: () => false,
    [AUTH_LOGOUT]: () => false,
    [AUTH_ERROR]: () => false,
  },
  true
);

const error = handleActions(
  {
    [AUTH_LOGIN]: () => null,
    [AUTH_LOGOUT]: () => null,
    [AUTH_ERROR]: (state, action) => action.payload,
  },
  null
);

const user = handleActions(
  {
    [AUTH_LOGIN]: (state, action) => action.payload,
    [AUTH_LOGOUT]: () => null,
    [AUTH_ERROR]: () => null,
    [PROFILE_UPDATED]: (state, action) => ({
      ...state,
      ...action.payload.data,
    }),
  },
  null
);

const login = handleActions(
  {
    [SHOW_MFA]: (state, action) => {
      if (action.payload && action.payload.mfa_required) {
        return {
          ...state,
          mfa_required: true,
        };
      }
      return {
        ...state,
        mfa_required: false,
      };
    },
  },
  null
);

export default combineReducers({
  loading,
  error,
  user,
  login,
});
