import { handleActions } from 'redux-actions';

import {
  SINGLE_SET,
  SINGLE_ERROR,
  STATE_UPDATED,
  RESOURCE_CREATED,
  RESOURCE_UPDATED,
  RESOURCE_SCHEMA_UPDATED,
  RESOURCE_DELETED,
} from '../actions/constants';

export default handleActions(
  {
    [SINGLE_SET]: (state, { payload: { id, response } }) => ({
      ...state,
      [id]: { response },
    }),
    [SINGLE_ERROR]: (state, { payload: { id, error } }) => ({
      ...state,
      [id]: { error },
    }),
    [RESOURCE_CREATED]: (state, { payload: { id, data } }) => {
      if (data.id) {
        if (data.notifications && data.notifications.length > 0) {
          // add temporary space within the keys (TODO: remove with final-form)
          // eslint-disable-next-line no-param-reassign
          data.notifications = data.notifications.map((notification) => {
            const newNotification = {};
            Object.keys(notification).forEach((key) => {
              if (key.startsWith('+') && key.indexOf(' ') === -1) {
                newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
              } else {
                newNotification[key] = notification[key];
              }
            });
            return newNotification;
          });
        } else if (data.config_notification && data.config_notification.length > 0) {
          // add temporary space within the keys (TODO: remove with final-form)
          // eslint-disable-next-line no-param-reassign
          data.config_notification = data.config_notification.map((notification) => {
            const newNotification = {};
            Object.keys(notification).forEach((key) => {
              if (key.startsWith('+') && key.indexOf(' ') === -1) {
                newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
              } else {
                newNotification[key] = notification[key];
              }
            });
            return newNotification;
          });
        }

        return { ...state, [`${id}-${data.id}`]: { response: data } };
      }
      if (data.datasource_id) {
        return { ...state, [`${id}-${data.datasource_id}`]: { response: data } };
      }
      return state;
    },
    [RESOURCE_UPDATED]: (state, { payload: { id, data } }) => {
      if (state[`${id}-${data.id}`]) {
        if (data.notifications && data.notifications.length > 0) {
          // add temporary space within the keys (TODO: remove with final-form)
          // eslint-disable-next-line no-param-reassign
          data.notifications = data.notifications.map((notification) => {
            const newNotification = {};
            Object.keys(notification).forEach((key) => {
              if (key.startsWith('+') && key.indexOf(' ') === -1) {
                newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
              } else {
                newNotification[key] = notification[key];
              }
            });
            return newNotification;
          });
        } else if (data.config_notification && data.config_notification.length > 0) {
          // add temporary space within the keys (TODO: remove with final-form)
          // eslint-disable-next-line no-param-reassign
          data.config_notification = data.config_notification.map((notification) => {
            const newNotification = {};
            Object.keys(notification).forEach((key) => {
              if (key.startsWith('+') && key.indexOf(' ') === -1) {
                newNotification[key.slice(0, 3) + ' ' + key.slice(3)] = notification[key];
              } else {
                newNotification[key] = notification[key];
              }
            });
            return newNotification;
          });
        }

        return { ...state, [`${id}-${data.id}`]: { response: data } };
      }
      if (state[`${id}-${data.datasource_id}`]) {
        return { ...state, [`${id}-${data.datasource_id}`]: { response: data } };
      }
      return state;
    },
    [RESOURCE_SCHEMA_UPDATED]: (state, { payload: { id, data } }) => {
      if (state[`${id}`]) {
        return {
          ...state,
          [`${id}`]: { response: { ...state[`${id}`].response, schema: data } },
        };
      }
      return state;
    },
    [RESOURCE_DELETED]: (state, { payload: { id, data } }) => {
      if (state[`${id}-${data.id}`]) {
        const newState = { ...state };
        delete newState[`${id}-${data.id}`];
        return newState;
      }
      if (state[`${id}-${data.datasource_id}`]) {
        const newState = { ...state };
        delete newState[`${id}-${data.datasource_id}`];
        return newState;
      }
      return state;
    },

    [STATE_UPDATED]: (state, { payload: { id, datasource_id, state_changes } }) => {
      // eslint-disable-next-line
      const key = `machine-${id}-datasources-${datasource_id}`;
      if (state[key] && state[key].response) {
        return {
          ...state,
          [key]: {
            ...state[key],
            response: {
              ...state[key].response,
              state_data: {
                ...state[key].response.state_data,
                ...state_changes, // eslint-disable-line
              },
            },
          },
        };
      }
      return state;
    },
  },
  {}
);
