import request from './request';

export function updateUserDetail(data) {
  return request.patch({ url: `/user/`, data });
}
export function updateDashboardOrder(data) {
  return request.post({ url: '/user/dashboard_order/', data });
}
export function getDashboardUsers() {
  return request.get({ url: `/entityuser/`, query: { select_dashboard_user: true } });
}
