import { injectIntl, defineMessages } from 'react-intl';
import queryString from 'query-string';
import dateMath from '../../../utils/dateMath';
import Button from '../../../components/common/Button';

const messages = defineMessages({
  SHIFT_TIMEFRAME_RIGHT: {
    id: 'TimeRangeShiftRight.SHIFT_TIMEFRAME_RIGHT',
    defaultMessage: 'Shift timeframe to the right',
  },
});

function shiftTimeframeRight(from, to, timezone_offset) {
  const fromDate = new Date(dateMath(from, timezone_offset)).getTime();
  const toDate = new Date(dateMath(to, timezone_offset)).getTime();
  const deltaT = Math.abs(toDate - fromDate);
  return { from: new Date(toDate).toISOString(), to: new Date(toDate + deltaT).toISOString() };
}

function navigate2({ navigate, location, query }) {
  navigate(`${location.pathname}?${queryString.stringify(query)}`);
}

function TimeRangeShiftRight({
  navigate,
  location,
  from,
  to,
  timezone_offset,
  intl: { formatMessage },
}) {
  return (
    <Button
      title={formatMessage(messages.SHIFT_TIMEFRAME_RIGHT)}
      icon="fa fa-chevron-right"
      onClick={() =>
        navigate2({ navigate, location, query: shiftTimeframeRight(from, to, timezone_offset) })
      }
      marginLeft
    />
  );
}

export default injectIntl(TimeRangeShiftRight);
