import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';
import Header from './Header';
import Footer from './Footer';
import Button from './Button';

const HolderDiv = styled.div`
  color: ${({ theme, isSecondary }) => (isSecondary ? theme.mainPageColor : theme.color)};
  background: ${({ theme, isSecondary }) =>
    isSecondary ? theme.mainPageBackground : theme.background};
  ${({ theme, isSecondary }) =>
    isSecondary && theme.mainPage.backgroundImage
      ? `background-image: ${theme.mainPage.backgroundImage};
   background-repeat: ${theme.mainPage.backgroundRepeat};
   background-position: ${theme.mainPage.backgroundPosition};
   background-size: ${theme.mainPage.backgroundSize};`
      : null};
`;

const StyledContainer = styled.div`
  color: ${({ theme, isSecondary }) => (isSecondary ? theme.mainPageDiv.color : theme.div.color)};
  background: ${({ theme, isSecondary }) =>
    isSecondary ? theme.mainPageDiv.background : theme.div.background};
`;

const messages = defineMessages({
  BUTTON_HOME: {
    id: 'CommonPage.BACK_HOME',
    defaultMessage: 'Home',
  },
});

function CommonPageContainerComponent({ isLogin, children, isSecondary, intl: { formatMessage } }) {
  return (
    <HolderDiv
      isSecondary={isSecondary}
      className="flex flex-column justify-center items-center vh-100"
    >
      <Header />
      {!isLogin ? (
        <header className="flex items-center mv4">
          <div className="flex-auto order-2 fw5 mv0">
            <Button label={formatMessage(messages.BUTTON_HOME)} icon="fa fa-home" to="/" />
          </div>
        </header>
      ) : null}
      <StyledContainer isSecondary={isSecondary} className="br2 pa3 mw6">
        {children}
      </StyledContainer>
      <Footer />
    </HolderDiv>
  );
}

export default injectIntl(CommonPageContainerComponent);
