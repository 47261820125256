import { applyMiddleware, compose, createStore } from 'redux';
import reducer from './reducers';
import initialDataMiddleware from './actions/initialData';

export default function configureStore() {
  const middlewares = [initialDataMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(reducer, undefined, composedEnhancers);

  return store;
}
