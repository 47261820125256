/* eslint-disable */
// TODO ^-- remove this

const deepCopy = (o) =>
  typeof o === 'object' && o !== null
    ? Array.isArray(o)
      ? o.map((e) => deepCopy(e))
      : Object.keys(o).reduce((r, k) => ((r[k] = deepCopy(o[k])), r), {})
    : o;

export default function convertDashboardToTemplate(dashboard) {
  const result = deepCopy(dashboard);

  result.widgets &&
    result.widgets.forEach((widgetData) => {
      if (widgetData.config.datasources) {
        widgetData.config.datasources = widgetData.config.datasources.map(
          () => '$_REF_function_raw_$'
        );
      }
      if (widgetData.config.datasource) {
        widgetData.config.datasource = '$_REF_function_raw_$';
      }
      if (widgetData.config.machines) {
        widgetData.config.machines = widgetData.config.machines.map(() => '$_REF_machine_$');
      }
      if (widgetData.config.machine) {
        widgetData.config.machine = '$_REF_machine_$';
      }
    });

  return result;
}
