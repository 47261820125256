import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import Button from '../../../components/common/Button';
import TextField from '../../../components/form/TextField';

const messages = defineMessages({
  LABEL_EMAIL: {
    id: 'LoginForm.LABEL_EMAIL',
    defaultMessage: 'E-Mail Address',
  },
  LABEL_PASSWORD: {
    id: 'LoginForm.LABEL_PASSWORD',
    defaultMessage: 'Password',
  },
  CHECKING_LOGIN_DATA: {
    id: 'LoginForm.CHECKING_LOGIN_DATA',
    defaultMessage: 'Checking login data...',
  },
  LOG_IN: {
    id: 'LoginForm.LOG_IN',
    defaultMessage: 'Login',
  },
  ERRORS_EMAIL: {
    id: 'LoginForm.ERRORS_EMAIL',
    defaultMessage: 'Please state your email',
  },
  ERRORS_PASSWORD: {
    id: 'LoginForm.ERRORS_PASSWORD',
    defaultMessage: 'Please state your password',
  },
  MFA_PASSCODE: {
    id: 'MFAForm.MFA_PASSCODE',
    defaultMessage: 'MFA Passcode',
  },
});

const Form = styled.form`
  min-width: 22rem;
`;
const FieldBlock = styled(Field)`
  height: 2.5rem;
  background-color: ${({ theme }) => theme.loginField.backgroundColor};
  color: ${({ theme }) => theme.loginField.color};
  border: ${({ theme }) => theme.loginField.border};
`;
const MessageContainer = styled.div`
  position: relative;
  height: 1rem;
  & > p {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const ErrorText = styled.p`
  color: #d7316c;
  width: 100%;
  text-align: center;
`;
const LoadingText = styled(ErrorText)`
  color: #3893c6;
`;

let globalformatMessage;
const required = (value) =>
  value
    ? undefined
    : globalformatMessage &&
      globalformatMessage({ id: 'Validator.MANDATORY_FIELD', defaultMessage: 'Mandatory Field' });

function LoginForm(props) {
  const {
    handleSubmit,
    submitting,
    error,
    intl: { formatMessage },
    isSecondary,
    showPasscode,
  } = props;

  if (formatMessage) {
    globalformatMessage = formatMessage;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FieldBlock
        isSecondary={isSecondary}
        id="inputEmail"
        className="f4"
        name="email"
        validate={required}
        component={TextField}
        type="text"
        autoFocus
        label={formatMessage(messages.LABEL_EMAIL)}
        autoComplete="email"
        // autoComplete="email webauthn"
      />
      <FieldBlock
        isSecondary={isSecondary}
        id="inputPassword"
        className="f4"
        name="password"
        validate={required}
        component={TextField}
        type="password"
        label={formatMessage(messages.LABEL_PASSWORD)}
        autoComplete="current-password"
        // autoComplete="current-password webauthn"
      />
      {showPasscode && (
        <FieldBlock
          isSecondary={isSecondary}
          id="inputPasscode"
          name="mfa_passcode"
          className="f4"
          validate={required}
          component={TextField}
          autoComplete="one-time-code"
          inputMode="numeric"
          maxLength="6"
          pattern="[0-9]*"
          type="text"
          label={formatMessage(messages.MFA_PASSCODE)}
          autoFocus
        />
      )}
      <MessageContainer className="mb1">
        {error && !submitting ? <ErrorText>{error}</ErrorText> : null}
        {submitting ? (
          <LoadingText>
            <FormattedMessage {...messages.CHECKING_LOGIN_DATA} />
          </LoadingText>
        ) : null}
      </MessageContainer>
      <Button
        large
        login
        activestyle="primary"
        block
        type="submit"
        disabled={submitting}
        label={formatMessage(messages.LOG_IN)}
      />
    </Form>
  );
}

function validateLoginForm(data, props) {
  const errors = {};
  const {
    intl: { formatMessage },
  } = props;

  if (!data.email) {
    errors.email = formatMessage(messages.ERRORS_EMAIL);
  }
  if (!data.password) {
    errors.password = formatMessage(messages.ERRORS_PASSWORD);
  }
  return errors;
}

export default injectIntl(
  reduxForm({
    form: 'login',
    validate: validateLoginForm,
    touchOnBlur: false,
  })(LoginForm)
);
