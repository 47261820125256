// import { createElement } from 'react';

import { connect } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

function RedirectIfLoggedInRoute({ user, children }) {
  const location = useLocation();
  const params = useParams();
  let token;

  if (user) {
    if (params.token) {
      // redirect if user registration token but user is logged in
      token = params.token;
    }

    let from = location.state && location.state.from && location.state.from;
    if (from && from.pathname && from.pathname.startsWith('/logout')) {
      // prevent logout loop
      from = null;
    }
    return <Navigate to={from || '/'} state={{ registration_code: token ? token : null }} />;
  }
  return children;
}

export default connect((state) => state.auth)(RedirectIfLoggedInRoute);
