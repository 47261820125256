import dateMath from './dateMath';

const intervalMap = [
  1,
  2,
  5,
  10,
  15,
  20,
  30,
  60,
  120,
  300,
  600,
  900,
  1200,
  1800,
  3600, // 1h
  5400, // 90m
  7200, // 2h
  10800, // 3h
  14400, // 4h
  21600, // 6h
  28800, // 8h
  43200, // 12h
  86400, // 1d
  129600, // 36h
];

export function getInterval(from, to, valuesCount = 100) {
  const fromDate = new Date(dateMath(from));
  const toDate = new Date(dateMath(to));

  let interval = (toDate - fromDate) / (valuesCount * 1000);

  if (interval < 129600) {
    interval = intervalMap.find(i => i > interval);
  }

  return interval;
}

export function calculateInterval(from, to, valuesCount = 100) {
  const interval = getInterval(from, to, valuesCount);

  if (interval >= 2419200) {
    return `${Math.round(interval / 2592000)}M`;
  }
  if (interval >= 604800) {
    return `${Math.round(interval / 604800)}w`;
  }
  if (interval >= 86400) {
    return `${Math.round(interval / 86400)}d`;
  }
  if (interval >= 3600 && interval % 3600 === 0) {
    return `${interval / 3600}h`;
  }
  if (interval >= 60 && interval % 60 === 0) {
    return `${interval / 60}m`;
  }
  return `${interval}s`;
}

export default calculateInterval;
