import { isNil } from 'lodash';
import {
  INITIAL_DATA_CLEAR,
  INITIAL_DATA_SET,
  INITIAL_DATA_ERROR,
  CREATE_MACHINE,
  UPDATE_MACHINE,
  UPDATE_MACHINE_LIST,
  UPDATE_DEVICE_CONFIG,
  SCHEMA_UPDATED,
  DELETE_MACHINE,
  CREATE_DATASOURCE,
  UPDATE_DATASOURCE,
  DELETE_DATASOURCE,
  LAST_SEEN_RECEIVED,
  DELETE_ENTITY,
  UPDATE_DEVICE_STATE,
  UPDATE_CURRENT_EVENT,
} from '../actions/constants';

export default function machinesReducer(state = {}, action) {
  if (action.type === INITIAL_DATA_SET) {
    if (isNil(action.payload.machinesObject)) {
      // initial state is null before server response, keep it null to prevent isLoading for users without any devices
      return state;
    }

    return { ...state, ...action.payload.machinesObject };
  }

  if (action.type === LAST_SEEN_RECEIVED) {
    if (action.payload && action.payload.id) {
      // const device_id = action.payload.id.slice(action.payload.id.length - 32);
      if (state && state[action.payload.id]) {
        return {
          ...state,
          [action.payload.id]: { ...state[action.payload.id], ...action.payload.data },
        };
      }
    }
  }

  if (action.type === CREATE_MACHINE) {
    return {
      ...state,
      [action.payload.data.id]: { ...action.payload.data },
    };
  }

  if (action.type === UPDATE_MACHINE_LIST) {
    if (action.payload && action.payload.data && action.payload.data.length) {
      const newState = { ...state };
      action.payload.data.forEach((device) => {
        newState[device.id] = { ...device };
      });

      return newState;
    }
  }

  if (action.type === UPDATE_MACHINE) {
    if (state && state[action.payload.data.id]) {
      return {
        ...state,
        [action.payload.data.id]: { ...state[action.payload.data.id], ...action.payload.data },
      };
    }
  }

  if (action.type === UPDATE_DEVICE_CONFIG) {
    if (state && state[action.payload.data.id]) {
      return {
        ...state,
        [action.payload.data.id]: {
          ...state[action.payload.data.id],
          config: action.payload.data.config,
        },
      };
    }
  }

  if (action.type === UPDATE_DEVICE_STATE) {
    if (state && state[action.payload.id]) {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], ...action.payload.data },
      };
    }
  }

  if (action.type === UPDATE_CURRENT_EVENT) {
    if (state && state[action.payload.id]) {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          current_event: {
            ...state[action.payload.id].current_event,
            ...action.payload.data.current_event,
          },
        },
      };
    }
  }

  if (action.type === DELETE_ENTITY) {
    // update all devices with new entity_id
    const oldEntityID = action.payload.id;
    const newEntityID = action.payload.data.parent_id;
    const newState = { ...state };
    Object.values(newState).forEach((d) => {
      if (d.entity_id === oldEntityID) {
        newState[d.id].entity_id = newEntityID;
      }
    });
    return newState;
  }

  if (action.type === SCHEMA_UPDATED) {
    if (state && state[action.payload.id]) {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], schema: action.payload.data },
      };
    }
  }

  if (action.type === DELETE_MACHINE) {
    if (state && state[action.payload.id]) {
      const newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    }
  }

  if (action.type === CREATE_DATASOURCE) {
    if (
      state &&
      state[action.payload.data.machine_id].datasources &&
      state[action.payload.data.machine_id].datasources.length
    ) {
      const datasources = state[action.payload.data.machine_id].datasources.map((ds) =>
        ds.datasource_id === action.payload.data.datasource_id ? action.payload.data : ds
      );
      return {
        ...state,
        [action.payload.data.machine_id]: {
          ...state[action.payload.data.machine_id],
          datasources: [...datasources, action.payload.data],
        },
      };
    }

    return {
      ...state,
      [action.payload.data.machine_id]: {
        ...state[action.payload.data.machine_id],
        datasources: [action.payload.data],
      },
    };
  }

  if (action.type === UPDATE_DATASOURCE) {
    if (state && state[action.payload.data.machine_id]) {
      const datasources = state[action.payload.data.machine_id].datasources.map((ds) =>
        ds.datasource_id === action.payload.data.datasource_id ? action.payload.data : ds
      );
      return {
        ...state,
        [action.payload.data.machine_id]: {
          ...state[action.payload.data.machine_id],
          datasources: datasources,
        },
      };
    }
  }

  if (action.type === DELETE_DATASOURCE) {
    if (state && action.payload.data && state[action.payload.data.machine_id]) {
      const datasources = state[action.payload.data.machine_id].datasources
        .filter((ds) => ds.datasource_id !== action.payload.data.datasource_id)
        .map((ds) => ds);
      return {
        ...state,
        [action.payload.data.machine_id]: {
          ...state[action.payload.data.machine_id],
          datasources: datasources,
        },
      };
    }
  }

  if (action.type === INITIAL_DATA_CLEAR || action.type === INITIAL_DATA_ERROR) {
    return null;
  }

  return state;
}
