import jwt_decode from 'jwt-decode';
import { getJWT } from './jwt';

export function isDESMApremium(state_config) {
  if (isDESMA(state_config)) {
    const token = getJWT();
    if (token) {
      const decodedAccessToken = jwt_decode(token);
      if (decodedAccessToken && decodedAccessToken.desma && decodedAccessToken.desma.premium) {
        return decodedAccessToken.desma.premium;
      }
    }
    return false;
  }

  return true;
}

export function isDESMA(state_config) {
  if (state_config.data) {
    return state_config.data.is_desma;
  }
  return false;
}
