import { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { components, createFilter } from 'react-select';

import request from '../../api/request';
import FieldWrapper from '../../components/form/FieldWrapper';
import StyledSelect from '../../controls/StyledSelect';
import { getValue } from '../../utils/selectinput';

const Wrapper = styled(FieldWrapper)`
  background: ${({ theme }) => theme.inputField.background};
  padding: 0.3rem;
  width: 400px;
  @media (max-width: 768px) {
    width: 330px;
  }
  box-shadow: ${({ theme }) => theme.div.boxShadow} !important;
  & > label {
    color: ${({ theme }) => theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
`;

const filterConfig = {
  stringify: (option) => {
    let config = `${option.label}`;
    if (option.data.is_external === true) {
      config += ` ext`;
    }

    return config;
  },
};

const Option = (props) => (
  <components.Option {...props}>
    <div>
      <div>{props.data.label}</div>
      {props.data.is_external === true && props.data.external_user_name ? (
        <div>
          <small className="light-red">{props.data.external_user_name}</small>
        </div>
      ) : null}
    </div>
  </components.Option>
);

class SelectDashboardsField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { options: [] };
    this.loadOptions = this.loadOptions.bind(this);
  }

  async loadOptions() {
    if (this.props.config.user_id !== this.props.user_id) {
      // external dashboard list
      try {
        const response = await request.get({
          url: '/dashboard/',
          query: { exclude_widgets: true },
          headers: { 'X-User-ID': this.props.config.user_id },
        });
        const options = response.results
          .map((dashboard) => ({
            value: dashboard.id,
            label: dashboard.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        this.setState(() => ({
          options: options,
        }));

        // eslint-disable-next-line no-empty
      } catch {}
    } else if (this.props.config.current_dashboard_id) {
      this.setState(() => ({
        options: this.props.dashboards
          .filter((dashboard) => dashboard.id !== this.props.config.current_dashboard_id)
          .map((dashboard) => ({
            value: dashboard.id,
            label: dashboard.name,
            is_external: dashboard.user_id !== this.props.user_id,
            external_user_name:
              dashboard.user_id !== this.props.user_id && dashboard.external_user_name
                ? dashboard.external_user_name
                : '',
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      }));
    } else {
      this.setState(() => ({
        options: this.props.dashboards
          .map((dashboard) => ({
            value: dashboard.id,
            label:
              dashboard.user_id !== this.props.user_id ? '[ext] ' + dashboard.name : dashboard.name,
            is_external: dashboard.user_id !== this.props.user_id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.config.user_id !== prevProps.config.user_id) {
      this.props.input.onChange(null);
      this.loadOptions();
    }
  }

  componentDidMount() {
    if (this.props.config.user_id) {
      this.loadOptions();
    }
  }

  render() {
    const { config, input, meta } = this.props;

    return (
      <Wrapper label={config.label} meta={meta}>
        <StyledSelect
          {...input}
          value={getValue(input.value, this.state.options)}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          options={this.state.options}
          components={{ Option }}
          onChange={(opt) => input.onChange(opt && opt.value)}
          onFocus={() => input.onFocus()}
          onBlur={() => input.onBlur()}
          placeholder={config.placeholder}
          filterOption={createFilter(filterConfig)}
          noOptionsMessage={() => config.label_not_found}
          isClearable={true}
        />
      </Wrapper>
    );
  }
}

export default connect((state) => ({
  user_id: state.auth.user.id,
  dashboards: Object.values(state.dashboards),
}))(SelectDashboardsField);
