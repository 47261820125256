import { handleActions } from 'redux-actions';

import { LANGUAGE_CHANGED, TIMEZONE_CHANGED } from '../actions/constants';
import { getBrowserLanguage } from '../utils/browserLanguage';

export default handleActions(
  {
    [LANGUAGE_CHANGED]: (state, action) => ({ ...state, ...action.payload }),
    [TIMEZONE_CHANGED]: (state, action) => ({ ...state, ...action.payload }),
  },
  { locale: getBrowserLanguage(), messages: {}, timeZone: 'Europe/Berlin' }
);
