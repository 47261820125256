import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

function LoginRequiredRoute({ user, children }) {
  const location = useLocation();
  if (user) {
    return children;
  }
  return <Navigate to="/login/" state={{ from: location }} />;
}

export default connect((state) => state.auth)(LoginRequiredRoute);
