import { injectIntl, defineMessages } from 'react-intl';
import { batch, connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { updateProfile } from '../../api/auth';
import { profileUpdated } from '../../actions/auth';
import { languageChanged, timezoneChanged } from '../../actions/intl';

import { Section, Header, Main } from '../../components/page';
import ProfileForm from './ProfileForm';

const messages = defineMessages({
  PROFILE_SETTINGS: {
    id: 'ProfilePage.PROFILE_SETTINGS',
    defaultMessage: 'Profile settings',
  },
  SUCCESS: {
    id: 'ProfilePage.SUCCESS',
    defaultMessage: 'Changes have been saved',
  },
});

function ProfilePage(props) {
  return (
    <Section>
      <Helmet title={props.intl.formatMessage(messages.PROFILE_SETTINGS)} />
      <Header text={props.intl.formatMessage(messages.PROFILE_SETTINGS)} />
      <Main>
        <ProfileForm
          initialValues={props.initialValues}
          onSubmit={props.onSubmit}
          onCancel={props.onCancel}
          supportedLanguages={props.supportedLanguages}
        />
      </Main>
    </Section>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      supportedLanguages: state.config.data.supportedLanguages || [],
      initialValues: {
        fullname: state.auth.user.fullname,
        email: state.auth.user.email,
        language: state.auth.user.language,
        timezone: state.auth.user.timezone,
        phone_numbers: state.auth.user.phone_numbers,
      },
    }),
    (dispatch, { navigate, intl: { formatMessage } }) => ({
      onSubmit: (data) =>
        updateProfile(data).then(
          (updateUser) => {
            batch(() => {
              dispatch(profileUpdated({ data: updateUser }));
              if (updateUser.language) {
                dispatch(languageChanged(updateUser.language));
              }
              if (updateUser.timezone) {
                dispatch(timezoneChanged(updateUser.timezone));
              }
              toast.success(formatMessage(messages.SUCCESS));
            });
          },
          (error) => {
            throw new SubmissionError(error);
          }
        ),
      onCancel: () => navigate('/'),
    })
  )(ProfilePage)
);
