import { useState } from 'react';
import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import md5 from 'md5';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DashboardItemDrag } from './DashboardItemDrag';
import { dashboardDeleted, dashboardOrderUpdated } from '../actions/dashboard';
import { updateDashboardOrder } from '../api/user';
import { DashboardItemNoDrag } from './DashboardItemNoDrag';

const LinksHolder = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  max-height: 500px;
  overflow: auto;
  margin-right: 1rem;
`;
// const LinkConn = styled(Link)`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   padding: 0.5rem 0 0.5rem 1.2rem;
//   text-decoration: none;
//   color: ${({ theme }) => theme.secondaryDiv.color};
//   &:hover {
//     background: ${({ theme }) => theme.secondaryDiv.backgroundHover};
//   }
// `;
// const DashboardIcon = styled(Dashboard)`
// margin-right: 1rem;
// color: ${({ theme }) => theme.secondaryDiv.color};
// font-size: 0.7rem;
// `;

// const DashboardTemplateIcon = styled(Dns)`
// margin-right: 1rem;
// color: ${({ theme }) => theme.secondaryDiv.color};
// font-size: 0.7rem;
// `;

const SearchField = styled.div`
  padding-left: 1.1rem;
  width: 100%;
`;

// const HoldingDiv = styled.div`
// width: 100%;
// `;

const InputField = styled.input`
  color: ${({ theme }) => theme.inputField.color};
  border: none;
  border-radius: 0.2rem;
  padding: 0.2rem;
  background: ${({ theme }) => theme.inputField.background};
  width: 80%;
`;

const ClearButton = styled.button`
  color: grey;
  border: none;
  background: transparent;
  padding: 0;
  vertical-align: middle;
  & > svg {
    font-size: 0.9rem;
    vertical-align: middle;
  }
`;

const messages = defineMessages({
  QUICK_NAVIGATION_PLACEHOLDER: {
    id: 'QuickNavigationComponent.QUICKNAVIGATION_PLACEHOLDER',
    defaultMessage: 'Quick Navigation',
  },
  DASHBOARDS: {
    id: 'QuickNavigationComponent.DASHBOARDS',
    defaultMessage: 'Dashboards',
  },
  MACHINE: {
    id: 'QuickNavigationComponent.MACHINE',
    defaultMessage: 'Machine',
  },
  OVERVIEW: {
    id: 'QuickNavigationComponent.OVERVIEW',
    defaultMessage: 'Overview',
  },
  OPERATIONAL_DATA: {
    id: 'QuickNavigationComponent.OPERATIONAL_DATA',
    defaultMessage: 'Operational data',
  },
  ORDERS: {
    id: 'QuickNavigationComponent.ORDERS',
    defaultMessage: 'Orders',
  },
  RAW_DATA: {
    id: 'QuickNavigationComponent.RAW_DATA',
    defaultMessage: 'Raw data',
  },
  FILTER: {
    id: 'QuickNavigationComponent.FILTER',
    defaultMessage: 'Filter...',
  },
});

function getOptions(dashboards, user) {
  const items = {};
  if (dashboards) {
    Object.values(dashboards).forEach((dashboard) => {
      if (dashboard.user_id !== user.id) {
        items[dashboard.id] = {
          label: dashboard.name,
          external_user_name: dashboard.external_user_name,
          is_external: true,
          path: `/dashboard/${dashboard.id}/`,
          object: dashboard,
          id: dashboard.id,
          description: dashboard.description ? dashboard.description : null,
        };
      } else {
        items[dashboard.id] = {
          label: dashboard.name,
          path: `/dashboard/${dashboard.id}/`,
          object: dashboard,
          id: dashboard.id,
          description: dashboard.description ? dashboard.description : null,
        };
      }
    });
  }

  return items;
}

export function DashboardListComponent(props) {
  const {
    intl: { formatMessage },
    options,
    updateDashboards,
    efentoDevices,
    current_dashboard_id,
    removeExternalDashboard,
    // onClick,
  } = props;

  const [searchStrg, setSearchStrg] = useState('');
  const [activeId, setActiveId] = useState(null);
  const [dashboardIDs, setDashboardIDs] = useState(Object.keys(options));
  const sensors = useSensors(useSensor(PointerSensor));

  return (
    <>
      <SearchField>
        <InputField
          placeholder={formatMessage(messages.FILTER)}
          type="text"
          autoComplete="off"
          onChange={(e) => setSearchStrg(e.target.value)}
          value={searchStrg}
        />
        <ClearButton type="button" onClick={() => setSearchStrg('')}>
          <Close />
        </ClearButton>
      </SearchField>
      <LinksHolder>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={dashboardIDs} strategy={verticalListSortingStrategy}>
            {dashboardIDs
              .filter((id) => {
                if (!options[id]) {
                  return false;
                }
                if (searchStrg) {
                  if (
                    options[id].label &&
                    options[id].label.toLowerCase().indexOf(searchStrg.toLowerCase()) !== -1
                  ) {
                    return true;
                  }

                  // find dashboards with this efento serial
                  if (
                    searchStrg.toUpperCase().startsWith('282C02') &&
                    searchStrg.length === 12 &&
                    efentoDevices.length
                  ) {
                    const efentoDeviceID = md5(searchStrg.toUpperCase());
                    const efentoDevice = efentoDevices.find((d) => d.id === efentoDeviceID);

                    if (efentoDevice) {
                      const efentoDatasource = efentoDevice.datasources.find(
                        (ds) => ds.function === 'data'
                      );
                      if (efentoDatasource) {
                        const efentoDatasourceID = efentoDatasource.datasource_id;

                        // find all widgets in all dashboards with this datasourceID
                        const stringifiedJson = JSON.stringify(options[id].object.widgets);
                        if (stringifiedJson.indexOf(efentoDatasourceID) !== -1) {
                          return true;
                        }
                      }
                    }
                  }

                  return false;
                }
                return true;
              })
              .map((id) => (
                <DashboardItemDrag
                  opt={options[id]}
                  key={id}
                  id={id}
                  handle={true}
                  current_dashboard_id={current_dashboard_id}
                  removeExternalDashboard={removeExternalDashboard}
                  sidebarToggle={props.sidebarToggle}
                />
              ))}
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <DashboardItemNoDrag
                id={activeId}
                opt={options[activeId]}
                current_dashboard_id={current_dashboard_id}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </LinksHolder>
    </>
  );

  function handleDragStart(event) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const newDashboardIDs = arrayMove(
        dashboardIDs,
        dashboardIDs.indexOf(active.id),
        dashboardIDs.indexOf(over.id)
      );
      setDashboardIDs(newDashboardIDs);
      updateDashboards(newDashboardIDs);
    }
    setActiveId(null);
  }
}

const DashboardListContainer = connect(
  (state) => ({
    options: getOptions(state.dashboards, state.auth.user),
    efentoDevices: Object.values(state.machine || {}).filter(
      (m) => m && m.internal_name && m.internal_name.startsWith('282C02')
    ),
  }),
  (dispatch) => ({
    // navigate: (url) => history.push({ pathname: url }),
    removeExternalDashboard: (external_dashboard_id) =>
      dispatch(dashboardDeleted({ id: external_dashboard_id })),

    // change order of dashboards by updating dashboard state with reducer
    updateDashboards: (data) =>
      updateDashboardOrder(data).then(() => {
        dispatch(dashboardOrderUpdated({ data: { dashboard_order: data } }));
      }),
  })
)(injectIntl(DashboardListComponent));

export default DashboardListContainer;
