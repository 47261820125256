import { injectIntl } from 'react-intl';
import { PureComponent } from 'react';
import isObject from 'lodash/isObject';

class CollapseChildren extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: props.alreadyOpen,
      // open: false,
      isInitial: false,
    };
  }

  componentDidUpdate() {
    if (!this.state.isInitial && this.props.alreadyOpen === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ open: true, isInitial: true });
    }
  }

  render() {
    const {
      children,

      style,
      className,
      intl: { formatMessage },
    } = this.props;

    let { label, labelOpen, labelClosed } = this.props;

    if (isObject(label)) {
      label = formatMessage(label);
    }
    if (isObject(labelOpen)) {
      labelOpen = formatMessage(labelOpen);
    }
    if (isObject(labelClosed)) {
      labelClosed = formatMessage(labelClosed);
    }

    return (
      <div>
        <div
          style={style}
          className={className || 'link pointer ssf-blue mb3'}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          {this.state.open ? labelOpen || '▾ ' + label : labelClosed || '▸ ' + label}
        </div>
        {this.state.open ? children : null}
      </div>
    );
  }
}

export default injectIntl(CollapseChildren);
