import { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
// import Error from './Error';

// const messages = defineMessages({
//   JAVASCRIPT_PROBLEM: {
//     id: 'ErrorBoundary.JAVASCRIPT_PROBLEM',
//     defaultMessage:
//       'Javascript failed to execute this component. If this is a widget, please try changing the configuration.',
//   },
// });

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });
    // You can also log error messages to an error reporting service here
  }

  render() {
    // const {
    //   // intl: { formatMessage },
    // } = this.props;

    if (this.state.errorInfo !== null) {
      // Error path
      return (
        <div className="pa2 overflow-scroll">
          There was an error loading this component:
          <br /> <br />
          {this.state.error && this.state.error.toString()}
          {/* <Error
            error={{
              error: this.state.error && this.state.error.toString(),
              stack: this.state.errorInfo.componentStack,
            }}
            hint={formatMessage(messages.JAVASCRIPT_PROBLEM)}
          /> */}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
export default injectIntl(ErrorBoundary);
