import { createAction } from 'redux-actions';

import {
  CONFIG_SET,
  CONFIG_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  SHOW_MFA,
  PROFILE_UPDATED,
} from './constants';

export const configSet = createAction(CONFIG_SET);
export const configError = createAction(CONFIG_ERROR);

export const authLogin = createAction(AUTH_LOGIN);
export const authLogout = createAction(AUTH_LOGOUT);
export const showMfa = createAction(SHOW_MFA);
export const authError = createAction(AUTH_ERROR);

export const profileUpdated = createAction(PROFILE_UPDATED);
