import { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { components, createFilter } from 'react-select';

import request from '../../api/request';
import FieldWrapper from '../../components/form/FieldWrapper';
import StyledSelect from '../../controls/StyledSelect';
import { getValue } from '../../utils/selectinput';
import { getParentAccountEntity, getParentDistributorEntity } from '../../utils/entity';

const Wrapper = styled(FieldWrapper)`
  background: ${({ theme }) => theme.inputField.background};
  padding: 0.3rem;
  width: 400px;
  @media (max-width: 768px) {
    width: 330px;
  }
  box-shadow: ${({ theme }) => theme.div.boxShadow} !important;
  & > label {
    color: ${({ theme }) => theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
`;

const filterConfig = {
  stringify: (option) => {
    const config = `${option.label} ${option.data.email}`;
    // if (option.data.account_name) {
    //   config += ` ${option.data.account_name}`;
    // }
    // if (option.data.account_location) {
    //   config += ` ${option.data.account_location}`;
    // }

    return config;
  },
};

const Option = (props) => (
  <components.Option {...props}>
    <div>
      <div>{props.data.label}</div>
      <div>
        <small>{props.data.email}</small>
      </div>
    </div>
  </components.Option>
);

class SelectUsersField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { options: [] };
    this.loadOptions = this.loadOptions.bind(this);
  }

  async loadOptions() {
    try {
      const {
        config: { is_select_dashboard = false, ignore_user_id },
        entities,
      } = this.props;
      const entity_users = await request.get({
        url: '/entityuser/',
        query: is_select_dashboard && { select_dashboard_user: is_select_dashboard },
      });

      if (entity_users && entity_users.results && entity_users.results.length) {
        if (entities) {
          // group by entity account/distributor
          const userByAccountEntity = {};
          let groupedOptions = [];
          entity_users.results.forEach((entity_user) => {
            if (entity_user.entity_ids && entity_user.entity_ids.length > 0) {
              entity_user.entity_ids.forEach((entity_id) => {
                let account_entity = getParentAccountEntity(entities, entity_id);
                if (!account_entity) {
                  account_entity = getParentDistributorEntity(entities, entity_id);
                }
                if (account_entity) {
                  if (!userByAccountEntity[account_entity.id]) {
                    userByAccountEntity[account_entity.id] = [];
                  }
                  userByAccountEntity[account_entity.id].push(entity_user);
                }
              });
            }
          });

          Object.keys(userByAccountEntity).forEach((account_entity_id) => {
            const entity = entities[account_entity_id];
            if (entity) {
              let entity_name = entity.name;

              if (entity.data) {
                if (entity.data.location != null && entity.data.location !== '') {
                  entity_name += ' (' + entity.data.location + ')';
                }
                if (entity.data.customer_id != null && entity.data.customer_id !== '') {
                  entity_name += ' (' + entity.data.customer_id + ')';
                }
              }

              const v = userByAccountEntity[account_entity_id]
                .filter((u) => {
                  if (ignore_user_id) {
                    if (u.user_id !== ignore_user_id) {
                      return true;
                    }
                  } else {
                    return true;
                  }
                  return false;
                })
                .map((user) => ({
                  value: user.user_id,
                  label: user.fullname,
                  email: user.email,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));

              if (v && v.length) {
                groupedOptions = [
                  ...groupedOptions,
                  {
                    label: entity_name,
                    options: v,
                  },
                ];
              }
            }
          });
          this.setState(() => ({
            options: groupedOptions.sort((a, b) => a.label.localeCompare(b.label)),
          }));
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  componentDidMount() {
    this.loadOptions();
  }

  render() {
    const { config, input, meta } = this.props;

    return (
      <Wrapper label={config.label} meta={meta}>
        <StyledSelect
          {...input}
          value={getValue(input.value, this.state.options)}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          options={this.state.options}
          components={{ Option }}
          onChange={(opt) => {
            if (config && config.full_value) {
              input.onChange(opt);
            } else {
              input.onChange(opt && opt.value);
            }
          }}
          onFocus={() => input.onFocus()}
          onBlur={() => input.onBlur()}
          placeholder={config.placeholder}
          filterOption={createFilter(filterConfig)}
          noOptionsMessage={() => config.label_not_found}
          isClearable={true}
        />
      </Wrapper>
    );
  }
}

export default connect((state) => ({ entities: state.entity }))(SelectUsersField);
