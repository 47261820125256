import { useState } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';

import LoginPage from './auth/LoginPage';
import PasswordResetPage from './auth/PasswordResetPage';
import UserRegistrationPage from './auth/UserRegistrationPage';
import PasswordResetDonePage from './auth/PasswordResetDonePage';
import PasswordResetConfirmPage from './auth/PasswordResetConfirmPage';
import PasswordResetCompleteView from './auth/PasswordResetCompleteView';
import LogoutPage from './auth/LogoutPage';
import NavigationPage from './NavigationPage';

import themeDark4 from '../themeDark4';
import themeLight from '../themeLight';

import AuthLoader from '../components/common/AuthLoader';
import ConfigLoader from '../components/common/ConfigLoader';
import LoginRequiredRoute from '../components/common/LoginRequiredRoute';
import RedirectIfLoggedInRoute from '../components/common/RedirectIfLoggedInRoute';
import Redirect from '../components/common/Redirect';
import Websocket from '../components/Websocket';

const ContentHolder = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
`;

const getTheme = (theme) => {
  if (theme === 'light') {
    return themeLight;
  }
  if (theme === 'dark') {
    return themeDark4;
  }
  return themeLight;
};

function ApplicationPage({ locale }) {
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <ConfigLoader
      render={(config) => (
        <ThemeProvider theme={getTheme(config.theme)}>
          <ContentHolder className="flex flex-column">
            <Websocket />
            <BrowserRouter>
              <AuthLoader loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <Helmet
                  htmlAttributes={{ lang: locale }}
                  defaultTitle={config.defaultTitle}
                  titleTemplate={config.titleTemplate}
                >
                  <link id="favicon" rel="icon" href={config.favicon} type="image/x-icon" />
                </Helmet>
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <RedirectIfLoggedInRoute>
                        <LoginPage setLoggedIn={setLoggedIn} />
                      </RedirectIfLoggedInRoute>
                    }
                  />
                  <Route
                    path="/logout"
                    element={
                      <LoginRequiredRoute>
                        <LogoutPage setLoggedIn={setLoggedIn} />
                      </LoginRequiredRoute>
                    }
                  />
                  <Route
                    path="/password_reset"
                    element={
                      <RedirectIfLoggedInRoute>
                        <PasswordResetPage />
                      </RedirectIfLoggedInRoute>
                    }
                  />
                  <Route
                    path="/password_reset/done"
                    element={
                      <RedirectIfLoggedInRoute>
                        <PasswordResetDonePage />
                      </RedirectIfLoggedInRoute>
                    }
                  />
                  <Route
                    path="/reset/:uid/:token/"
                    element={
                      <RedirectIfLoggedInRoute>
                        <PasswordResetConfirmPage />
                      </RedirectIfLoggedInRoute>
                    }
                  />
                  <Route
                    path="/reset/done/"
                    element={
                      <RedirectIfLoggedInRoute>
                        <PasswordResetCompleteView />
                      </RedirectIfLoggedInRoute>
                    }
                  />
                  <Route
                    path="/register/:token/"
                    element={
                      <RedirectIfLoggedInRoute>
                        <UserRegistrationPage />
                      </RedirectIfLoggedInRoute>
                    }
                  />
                  <Route
                    path="/register/:token/d/:dashboard_id/"
                    element={
                      <RedirectIfLoggedInRoute>
                        <UserRegistrationPage />
                      </RedirectIfLoggedInRoute>
                    }
                  />
                  {config.contact_url ? (
                    <Route path="/contact/*" element={<Redirect url={config.contact_url} />} />
                  ) : null}
                  {config.imprint_url ? (
                    <Route path="/imprint/*" element={<Redirect url={config.imprint_url} />} />
                  ) : null}
                  {config.tos_url ? (
                    <Route path="/termsofuse/*" element={<Redirect url={config.tos_url} />} />
                  ) : null}
                  {config.privacy_url ? (
                    <Route
                      path="/privacystatement/*"
                      element={<Redirect url={config.privacy_url} />}
                    />
                  ) : null}
                  <Route
                    path="*"
                    element={
                      <LoginRequiredRoute>
                        <NavigationPage />
                      </LoginRequiredRoute>
                    }
                  />
                </Routes>
              </AuthLoader>
            </BrowserRouter>
          </ContentHolder>
        </ThemeProvider>
      )}
    />
  );
}

export default connect((state) => ({
  intl: state.intl,
}))(ApplicationPage);
