import { injectIntl, defineMessages } from 'react-intl';

import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
import request from '../../api/request';
import { authLogout } from '../../actions/auth';
import NotFound from '../../components/NotFound';

import DeleteForm from '../../components/DeleteForm';
import { Section, Header, Main } from '../../components/page';

const messages = defineMessages({
  HEADER: {
    id: 'ProfileFormComponent.DELETE_MY_USER',
    defaultMessage: 'Delete my user',
  },
});

function UserDeletePage({ user, is_admin, location, onSubmit, onCancel, intl: { formatMessage } }) {
  if (is_admin) {
    return <NotFound pathname={location.pathname} />;
  }

  return (
    <Section>
      <Helmet title={user.name} />
      <Header text={formatMessage(messages.HEADER)} />
      <Main>
        <DeleteForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          name={user && user.fullname}
          showPasswordField
        />
      </Main>
    </Section>
  );
}

export default injectIntl(
  connect(
    (state) => ({ user: state.auth.user }),
    (dispatch, { navigate, intl: { formatMessage } }) => ({
      onSubmit: async (data) => {
        try {
          await request.del({ url: `/user/`, data });

          dispatch(authLogout());
          toast.success(
            formatMessage({
              id: 'ProfileFormComponent.DELETE_SUCCESS',
              defaultMessage: 'User deleted successfully',
            })
          );
        } catch (error) {
          if (error && error._status && error.message) {
            toast.error(error._status + ': ' + error.message);
          }
          throw new SubmissionError(error);
        }
      },
      onCancel: () => navigate('/profile/'),
    })
  )(injectIntl(UserDeletePage))
);
