import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import { Link } from 'react-router-dom';

const messages = defineMessages({
  CONTACT: {
    id: 'FooterComponent.CONTACT',
    defaultMessage: 'Contact',
  },
  IMPRINT: {
    id: 'FooterComponent.IMPRINT',
    defaultMessage: 'Imprint',
  },
  TERMS_OF_USE: {
    id: 'FooterComponent.TERMS_OF_USE',
    defaultMessage: 'Terms of Use',
  },
  PRIVACY_STATEMENT: {
    id: 'FooterComponent.PRIVACY_STATEMENT',
    defaultMessage: 'Privacy Statement',
  },
});

function FooterComponent(props) {
  const { config } = props;
  return (
    <footer className="pa3 b--black-10 black-70">
      {config.privacy_url ? (
        <Link to="/privacystatement/" className="f6 dib pr2 mid-gray dim" target="_blank">
          <FormattedMessage {...messages.PRIVACY_STATEMENT} />
        </Link>
      ) : null}
      {config.tos_url ? (
        <Link to="/termsofuse/" className="f6 dib pr2 mid-gray dim" target="_blank">
          <FormattedMessage {...messages.TERMS_OF_USE} />
        </Link>
      ) : null}
      {config.contact_url ? (
        <Link to="/contact/" className="f6 dib pr2 mid-gray dim" target="_blank">
          <FormattedMessage {...messages.CONTACT} />
        </Link>
      ) : null}
      {config.imprint_url ? (
        <Link to="/imprint/" className="f6 dib pr2 mid-gray dim" target="_blank">
          <FormattedMessage {...messages.IMPRINT} />
        </Link>
      ) : null}
    </footer>
  );
}

export default connect((state) => ({
  config: state.config && state.config.data,
}))(injectIntl(FooterComponent));
