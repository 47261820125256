import { PureComponent } from 'react';
import styled from 'styled-components';
import ErrorBoundary from './ErrorBoundary';
import Button from './common/Button';

const SECTION_STYLE = {
  maxWidth: '860px',
};

const MainBlock = styled.main`
  color: ${({ theme }) => theme.color};
  background: ${({ theme }) => theme.background};
  background-color: ${(props) => props.backgroundColor};
`;
export function Section({ style, children, backgroundColor }) {
  let myStyle = { ...SECTION_STYLE };
  if (style) {
    myStyle = { ...myStyle, ...style };
  }

  return (
    <MainBlock className="flex-auto overflow-auto" backgroundColor={backgroundColor}>
      <section className="center w-100 mv4 ph3" style={myStyle}>
        {children}
      </section>
    </MainBlock>
  );
}

export function Header({ text, children, backTo, backLabel }) {
  return (
    <header className="flex items-center mv2">
      {backTo ? (
        <div className="flex-auto order-2 fw5 mv0 pl2">
          <Button label={backLabel || 'Back'} icon="fa fa-arrow-left" to={backTo} />
        </div>
      ) : null}
      {text ? <h1 className="flex-auto order-2 fw5 mv0">{text}</h1> : null}
      {children}
    </header>
  );
}

export function HeaderLeft({ children }) {
  return <aside className="flex-none order-1 pr3 mr-auto">{children}</aside>;
}

export function HeaderRight({ children }) {
  return <aside className="flex-none order-3 pl3 ml-auto">{children}</aside>;
}

export class Main extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <ErrorBoundary>
        <main className="mv4">{children}</main>
      </ErrorBoundary>
    );
  }
}

export function Footer({ children }) {
  return <footer className="mv4">{children}</footer>;
}
