import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

const StyledContainer = styled.div`
  color: ${({ theme }) => theme.div.color};
  background: ${({ theme }) => theme.div.background};
`;

const messages = defineMessages({
  NOT_FOUND_PAGE_TITLE: {
    id: 'NotFound.NOT_FOUND_PAGE_TITLE',
    defaultMessage: 'Page not Found',
  },
  NOT_FOUND_PAGE_MESSAGE: {
    id: 'NotFound.NOT_FOUND_PAGE_MESSAGE',
    defaultMessage: ' We could not find the page you are looking for (Error 404).',
  },
  BACK_TO_START_PAGE: {
    id: 'NotFound.BACK_TO_START_PAGE',
    defaultMessage: 'Back to start page',
  },
});

export default function NotFound(props) {
  return (
    <div className="flex-auto flex justify-center items-center">
      <StyledContainer className="pa3 w6">
        <h3 className="mt0">
          <FormattedMessage {...messages.NOT_FOUND_PAGE_TITLE} />
        </h3>
        <p>
          <FormattedMessage {...messages.NOT_FOUND_PAGE_MESSAGE} />
        </p>
        <p>
          <code>{props.pathname}</code>
        </p>

        <Link className="db bg-primary white pa2 br2 link tc" to="/">
          <FormattedMessage {...messages.BACK_TO_START_PAGE} />
        </Link>
      </StyledContainer>
    </div>
  );
}
