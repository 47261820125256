import classnames from 'classnames';

export default ({
  className,
  firstColumn,
  firstColumnClassName = 'w-50',
  secondColumn,
  secondColumnClassName = 'w-50',
}) => (
  <div className={classnames('flex', className)}>
    <div className={classnames('flex-auto mr2', firstColumnClassName)}>{firstColumn}</div>
    <div className={classnames('flex-auto ml2', secondColumnClassName)}>{secondColumn}</div>
  </div>
);
