import { handleActions } from 'redux-actions';

import { FULLSCREEN_ENTER, FULLSCREEN_EXIT } from '../actions/constants';

export default handleActions(
  {
    [FULLSCREEN_ENTER]: () => true,
    [FULLSCREEN_EXIT]: () => false,
  },
  false
);
