// import { toast } from 'react-toastify';
import request from './request';

import { getBrowserLanguage } from '../utils/browserLanguage';
import { getJWT, getRefreshJWT } from '../utils/jwt';
import { sendMessage } from '../components/Websocket';

export function renewJWTRefreshToken({ navigate }) {
  const refreshToken = getRefreshJWT();
  if (refreshToken) {
    request
      .get({
        url: '/refreshtoken/',
        headers: { Authorization: 'Bearer ' + refreshToken },
        returnResponse: true,
      })
      .then(
        (res) => {
          if (
            res &&
            res.headers &&
            res.headers['x-authorization-token'] &&
            res.headers['x-refresh-token']
          ) {
            const newAccessToken = res.headers['x-authorization-token'];
            newAccessToken && localStorage.setItem('access_token', newAccessToken);

            const newRefreshToken = res.headers['x-refresh-token'];
            newRefreshToken && localStorage.setItem('refresh_token', newRefreshToken);

            if (process.env.NODE_ENV === 'development') {
              // eslint-disable-next-line
              console.log('renewed JWT access and refresh token');
            }

            // send new JWT via websocket
            if (newAccessToken) {
              sendMessage('Bearer ' + newAccessToken);
            }
          }
        },
        (error) => {
          if (error && error._status && error._status === 401) {
            if (process.env.NODE_ENV === 'development') {
              // eslint-disable-next-line
              console.log('error when renewing refresh and access token:', error);
              // throw error;
            }
            // logout
            // toast.warn('Your session has expired. Please log in again', {
            //   autoClose: false,
            //   theme: 'colored',
            // });
            navigate('/logout/');
          }
        }
      );
  } else {
    navigate('/logout/');
  }
}

export function renewJWTAccessToken({ navigate }) {
  const refreshToken = getRefreshJWT();
  if (refreshToken) {
    request
      .get({
        url: '/accesstoken/',
        headers: { Authorization: 'Bearer ' + refreshToken },
        returnResponse: true,
      })
      .then(
        (res) => {
          if (res && res.headers && res.headers['x-authorization-token']) {
            const newAccessToken = res.headers['x-authorization-token'];
            newAccessToken && localStorage.setItem('access_token', newAccessToken);

            if (process.env.NODE_ENV === 'development') {
              // eslint-disable-next-line
              console.log('refreshed JWT access token');
            }

            // send new JWT via websocket
            if (newAccessToken) {
              sendMessage('Bearer ' + newAccessToken);
            }
          }
        },
        (error) => {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line
            console.log('error when renewing access token:', error);

            // throw error;
          }
          if (error && error._status && error._status === 401) {
            // logout
            // toast.warn('Your session has expired. Please log in again', {
            //   autoClose: false,
            //   theme: 'colored',
            // });
            navigate('/logout/');

            // throw error;
          }
        }
      );
  } else {
    navigate('/logout/');
  }
}

export function checkAuthData() {
  // check if access_token is valid
  return request.get({
    url: '/auth/',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') },
    returnResponse: true,
  });
}

export function login(data) {
  return request.post({ url: '/auth/', data, returnResponse: true });
}

export function logout() {
  request.get({ url: '/logout/' });
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  return Promise.resolve();
}

export function updateProfile(data) {
  return request.put({ url: '/profile/', data });
}

export function config() {
  const accessToken = getJWT();
  if (accessToken) {
    return request.get({ url: '/config/' });
  }
  return request.get({ url: '/config/', anonymous: true, query: { lang: getBrowserLanguage() } });
}
