import { PureComponent } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { connect } from 'react-redux';

import { logout } from '../../api/auth';
import { authLogout } from '../../actions/auth';

import CommonPageContainer from '../../components/common/CommonPageContainer';
import { withHOCSRouter } from '../../components/hocs';

const messages = defineMessages({
  LOGOUT: {
    id: 'LogoutPageComponent.LOGOUT',
    defaultMessage: 'Sie werden ausgeloggt...',
  },
});

export class LogoutPageComponent extends PureComponent {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return (
      <CommonPageContainer>
        <FormattedMessage {...messages.LOGOUT} />
      </CommonPageContainer>
    );
  }
}

export default withHOCSRouter(connect(null, (dispatch, { navigate, setLoggedIn }) => ({
  logout: () =>
    logout().then(() => {
      // info for AuthLoader to remove token timers
      setLoggedIn(false);
      dispatch(authLogout());
      navigate('/', { replace: true });
    }),
}))(LogoutPageComponent));
