import { defineMessages, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import StringField from '../../../components/form/StringField';
import SelectFieldDefault from '../../../components/form/SelectFieldDefault';
import BooleanField from '../../../components/form/BooleanField';
import Button from '../../../components/common/Button';

const messages = defineMessages({
  NAME: {
    id: 'UserForm.NAME',
    defaultMessage: 'Name',
  },
  EMAIL: {
    id: 'UserPage.EMAIL',
    defaultMessage: 'Email',
  },
  LANGUAGE: {
    id: 'ProfileFormComponent.LABEL_LANGUAGE',
    defaultMessage: 'Language',
  },
  PRIVACY_CHECKBOX_CONSENT: {
    id: 'UserForm.PRIVACY_CHECKBOX_CONSENT',
    defaultMessage:
      'I have taken note of the privacy policy. I consent to my data being collected and stored electronically.',
  },
  SAVING: {
    id: 'UserForm.SAVING',
    defaultMessage: 'Register...',
  },
  BUTTON_REGISTER: {
    id: 'UserForm.REGISTER',
    defaultMessage: 'Register',
  },
  MANDATORY_FIELD: {
    id: 'Validator.MANDATORY_FIELD',
    defaultMessage: 'Mandatory Field',
  },
});

let globalformatMessage;

const email = (value) =>
  value &&
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    value
  )
    ? globalformatMessage && globalformatMessage(messages.MANDATORY_FIELD)
    : undefined;

const UserRegistrationComponent = ({
  handleSubmit,
  submitting,
  intl: { formatMessage },
  supportedLanguages = [],
  language = "en",
}) => {
  if (formatMessage) {
    globalformatMessage = formatMessage;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="name"
        component={StringField}
        config={{
          autoFocus: true,
          label: formatMessage(messages.NAME),
          autoComplete: 'name',
        }}
        no_shadow
      />
      <Field
        name="email"
        component={StringField}
        config={{
          label: formatMessage(messages.EMAIL),
          autoComplete: 'email',
        }}
        validate={email}
        no_shadow
      />
      <Field
        name="language"
        component={SelectFieldDefault}
        config={{
          label: formatMessage(messages.LANGUAGE),
          options: supportedLanguages,
          isClearable: false,
          defaultValue: language,
        }}
        no_shadow
      />
      <Field
        name="privacy_policy_checked"
        component={BooleanField}
        config={{
          label: formatMessage(messages.PRIVACY_CHECKBOX_CONSENT),
          link: '/privacystatement/',
        }}
      />
      {submitting ? <p className="info tc">{formatMessage(messages.SAVING)}</p> : null}
      <div className="tr">
        <Button
          activestyle="primary"
          type="submit"
          disabled={submitting}
          label={formatMessage(messages.BUTTON_REGISTER)}
        />
      </div>
    </form>
  );
};

function validateRegistrationForm(data, { intl: { formatMessage } }) {
  const errors = {};
  if (!data.name) {
    errors.name = formatMessage(messages.MANDATORY_FIELD);
  }
  if (!data.email) {
    errors.email = formatMessage(messages.MANDATORY_FIELD);
  }
  if (!data.language) {
    errors.language = formatMessage(messages.MANDATORY_FIELD);
  }
  if (!data.privacy_policy_checked || data.privacy_policy_checked === false) {
    errors.privacy_policy_checked = formatMessage(messages.MANDATORY_FIELD);
  }

  return errors;
}

export default injectIntl(
  reduxForm({ form: 'user', validate: validateRegistrationForm })(UserRegistrationComponent)
);
