import { handleActions } from 'redux-actions';

import { SET_SEARCH_PARAMS } from '../actions/constants';

const emptyState = { from: null, to: null, filter: null, isRealtime: null };

export default handleActions(
  {
    [SET_SEARCH_PARAMS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  emptyState
);
