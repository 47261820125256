import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { toast } from 'react-toastify';
import SelectUserDashboardForm from './form/SelectUserDashboardForm';
import { withHOCSRouter } from '../components/hocs';
import { copyDashboard } from '../api/dashboard';
import { dashboardCreated } from '../actions/dashboard';

const messages = defineMessages({
  SUCCESS: {
    id: 'DashboardContainerPage.SUCCESS_DASHBOARD_COPIED',
    defaultMessage: 'Dashboard copied successfully',
  },
  ERROR: {
    id: 'DashboardContainerPage.ERROR_USER_REGISTRATION_CODE',
    defaultMessage: 'An error has occurred. Please try again',
  },
});

const SelectUserDashboardComponent = (props) => <SelectUserDashboardForm {...props} />;

export default injectIntl(
  connect(
    (state) => ({
      is_select_dashboard: true,
      user: state.auth.user,
    }),
    (dispatch, { intl: { formatMessage } }) => ({
      copyDashboard: async ({ user_id, copy_dashboard_id, self_user_id, dashboard_order }) => {
        if (user_id && copy_dashboard_id) {
          try {
            const createdDashboard = await copyDashboard(copy_dashboard_id, user_id);
            if (createdDashboard && createdDashboard.status === 201) {
              toast.success(formatMessage(messages.SUCCESS));

              dispatch(dashboardCreated({ data: createdDashboard.body }));

              return true;
            }
            toast.warn(formatMessage(messages.ERROR));
          } catch (error) {
            toast.warn(formatMessage(messages.ERROR));
          }
        }

        return false;
      },
    })
  )(withHOCSRouter(SelectUserDashboardComponent))
);
