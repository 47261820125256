import { connect } from 'react-redux';
// import RavenJS from 'raven-js';
import { injectIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { Error, Cancel, InfoOutlined } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

const ErrorField = styled.div`
  background-color: ${({ error, theme, info, info_non_transparent }) =>
    checkForColor({
      error,
      info,
      info_non_transparent,
      errorColor: theme.errorComponent && theme.errorComponent.backgroundErrorColor,
      infoColor: theme.errorComponent && theme.errorComponent.backgroundInfoColor,
      infoNonTransparentColor:
        theme.errorComponent && theme.errorComponent.backgroundInfoNonTransparentColor,
      errorField: theme.errorField && theme.errorField.backgroundColor,
    })};
  color: ${({ error, theme, info }) =>
    checkForColor({
      error,
      info,
      errorColor: theme.errorComponent && theme.errorComponent.errorColor,
      infoColor: theme.errorComponent && theme.errorComponent.infoColor,
      errorField: theme.errorField && theme.errorField.color,
    })};
  border: ${({ error, theme, info }) =>
    checkForColor({
      error,
      info,
      errorColor: theme.errorComponent && theme.errorComponent.borderErrorColor,
      infoColor: theme.errorComponent && theme.errorComponent.borderInfoColor,
      errorField: theme.errorField && theme.errorField.border,
    })};
    box-shadow: ${({ theme }) => theme.mainBoxshadow} !important;
    padding: 0.75rem;
  }
`;
const MailLink = styled.a`
  color: ${({ error, theme, info }) =>
    checkForColor({
      error,
      info,
      errorColor: theme.errorComponent && theme.errorComponent.mailErrorColor,
      infoColor: theme.errorComponent && theme.errorComponent.mailInfoColor,
      errorField: theme.errorField && theme.errorField.color,
    })};
  font-weight: bolder;
  text-decoration: underline;
`;

function checkForColor({
  error,
  info,
  info_non_transparent,
  errorColor,
  infoColor,
  infoNonTransparentColor,
  errorField,
}) {
  if (error) {
    return errorColor;
  }
  if (info) {
    return infoColor;
  }
  if (info_non_transparent) {
    return infoNonTransparentColor;
  }
  return errorField;
}

const Holder = styled.div`
  display: flex;
  align-items: center;
`;
const ErrorTitle = styled.h4`
  font-size: 1rem;
  margin-bottom: 0.75rem;
`;
const ErrorMessageHint = styled.p`
  line-height: 1rem;
  margin: 0;
  font-size: 0.8rem;
`;
const List = styled.ul`
  & > li {
    margin-bottom: 0.5rem;
  }
`;
const Details = styled.details`
  margin-bottom: 0.2rem;
`;
const InfoField = styled(ErrorField)``;

const messages = defineMessages({
  ERROR_PAGE_TITLE: {
    id: 'Error.ERROR_PAGE_TITLE',
    defaultMessage: 'We are sorry but an error has occured',
  },
  DISPLAY_ERROR_MESSAGE: {
    id: 'Error.DISPLAY_ERROR_MESSAGE',
    defaultMessage: 'Please click to see the detailed error message',
  },
  PLEASE_CONFIGURE: {
    id: 'Error.PLEASE_CONFIGURE',
    defaultMessage: 'Please configure your widget.',
  },
  PLEASE_CONFIGURE_DETAILS: {
    id: 'Error.PLEASE_CONFIGURE_DETAILS',
    defaultMessage:
      'To configure your widget, click on the edit button of the dashboard and then on the button with the pen symbol on this widget.',
  },
  LOADING: {
    id: 'Error.LOADING',
    defaultMessage: 'Please wait for data to load.',
  },
  NO_RESULTS: {
    id: 'Error.NO_RESULTS',
    defaultMessage: 'The request you made did not return any results',
  },
  NO_RESULTS_MMS: {
    id: 'Error.NO_RESULTS_MMS',
    defaultMessage: 'No data at the moment',
  },
  NO_RESULTS_DETAILS: {
    id: 'Error.NO_RESULTS_DETAILS',
    defaultMessage:
      'You can change the dashboards time range, the widgets configuration or the machine filter to alter the results.',
  },
  DEFAULT_HINTS: {
    id: 'Error.DEFAULT_HINTS',
    defaultMessage: 'If this did not work, please click here to see what else you could try.',
  },
  DEFAULT_HINTS_WO_HINT: {
    id: 'Error.DEFAULT_HINTS_WO_HINT',
    defaultMessage: 'Please click here to see what you could try now.',
  },
  DEFAULT_HINT_RELOAD: {
    id: 'Error.DEFAULT_HINT_RELOAD',
    defaultMessage:
      'Sometimes things get lost while transmitting them through the internet: So please reload your browser page.',
  },
  DEFAULT_HINT_LOGIN: {
    id: 'Error.DEFAULT_HINT_LOGIN',
    defaultMessage:
      'You may have lost your platform session: So please open the menu and log out. Then log in again',
  },
  DEFAULT_HINT_CHANGE_TIME: {
    id: 'Error.DEFAULT_HINT_CHANGE_TIME',
    defaultMessage:
      'If you are on a dashboard, your time frame may not contain any data. Please change it using the buttons on the top',
  },
  DEFAULT_HINT_CHECK_CONFIG: {
    id: 'Error.DEFAULT_HINT_CHECK_CONFIG',
    defaultMessage:
      'Have you checked your widgets configuration? Maybe the values you entered are not exactly what you wanted to do?',
  },
  DEFAULT_HINT_WAIT: {
    id: 'Error.DEFAULT_HINT_WAIT',
    defaultMessage:
      'Rarely it happens that the platform is too busy to handly your request properly. Maybe have a nice cup of coffee and try again in a few minutes',
  },
  DEFAULT_HINT_SUPPORT_1: {
    id: 'Error.DEFAULT_HINT_SUPPORT_1',
    defaultMessage: 'If this all fails, please contact our support via',
  },
  DEFAULT_HINT_SUPPORT_2: {
    id: 'Error.DEFAULT_HINT_SUPPORT_2',
    defaultMessage:
      'It would be really helpful for us if you could describe your problem very detailed, so that we can reproduce it and please attach a screenshot of this error message after you clicked on',
  },
});

function getString(error) {
  if (error instanceof Error) {
    return error.toString();
  }
  return typeof error === 'object' ? JSON.stringify(error) : error;
}

function ShowInfo(displayInfo, error, showInfoHead, showInfoMessage) {
  return (
    <Holder>
      {error ? <Cancel className="mr2" /> : null}
      {displayInfo ? <InfoOutlined className="mr2" /> : null}
      <div>
        {showInfoHead ? <ErrorTitle className="mt0">{showInfoHead}</ErrorTitle> : null}
        <ErrorMessageHint>{showInfoMessage}</ErrorMessageHint>
      </div>
    </Holder>
  );
}

// SSF default error component,
// example usages can be found in ../__tests__/ErrorComponent.spec.js
export const ErrorComponent = ({
  error,
  hint,
  info,
  info_non_transparent,
  info_message,
  please_configure,
  loading,
  no_results,
  blank,
  supportMail,
  flat,
  no_results_mms,
  intl: { formatMessage },
}) => {
  let showInfoHead = info || info_non_transparent;
  let showInfoMessage = info_message;

  if (!info && please_configure) {
    showInfoHead = formatMessage(messages.PLEASE_CONFIGURE);
    showInfoMessage = formatMessage(messages.PLEASE_CONFIGURE_DETAILS);
  }

  if (!info && loading) {
    showInfoHead = formatMessage(messages.LOADING);
    showInfoMessage = null;
  }

  if (!info && no_results) {
    showInfoHead = formatMessage(messages.NO_RESULTS);
    showInfoMessage = formatMessage(messages.NO_RESULTS_DETAILS);
  }

  if (info && no_results_mms) {
    showInfoHead = formatMessage(messages.NO_RESULTS_MMS);
  }

  if (loading) {
    return (
      <div className="flex-auto flex justify-center items-center">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (flat && (showInfoHead || showInfoMessage)) {
    return (
      <div className="flex-auto flex justify-center items-center">
        {showInfoHead || null}
        {showInfoMessage ? (
          <>
            <br />
            {showInfoMessage}
          </>
        ) : null}
      </div>
    );
  }

  if (blank) {
    return (
      <div className="flex-auto flex justify-center items-center">
        <br />
      </div>
    );
  }

  if (showInfoHead || showInfoMessage) {
    const displayInfo = info || info_non_transparent;

    if (info_non_transparent) {
      return (
        <div className="flex-auto flex justify-center items-center">
          <InfoField info_non_transparent className="br2 ba w6">
            {ShowInfo(displayInfo, error, showInfoHead, showInfoMessage)}
          </InfoField>
        </div>
      );
    }

    return (
      <div className="flex-auto flex justify-center items-center">
        <InfoField info className="br2 ba w6">
          {ShowInfo(displayInfo, error, showInfoHead, showInfoMessage)}
        </InfoField>
      </div>
    );
  }

  return (
    <div className="flex-auto flex justify-center items-center">
      <ErrorField error className="br2 ba w6">
        <ErrorTitle className="mt0 ssf-red">{formatMessage(messages.ERROR_PAGE_TITLE)}</ErrorTitle>
        {hint ? (
          <div className="ph1 pb3 b flex items-center">
            <Error className="mr2" />
            <ErrorMessageHint>{hint}</ErrorMessageHint>
          </div>
        ) : null}
        <Details>
          <summary>
            {hint
              ? formatMessage(messages.DEFAULT_HINTS)
              : formatMessage(messages.DEFAULT_HINTS_WO_HINT)}
          </summary>
          <p>
            <List>
              <li>{formatMessage(messages.DEFAULT_HINT_RELOAD)}</li>
              <li>{formatMessage(messages.DEFAULT_HINT_LOGIN)}</li>
              <li>{formatMessage(messages.DEFAULT_HINT_CHANGE_TIME)}</li>
              <li>{formatMessage(messages.DEFAULT_HINT_CHECK_CONFIG)}</li>
              <li>{formatMessage(messages.DEFAULT_HINT_WAIT)}</li>
              {supportMail ? (
                <li>
                  {formatMessage(messages.DEFAULT_HINT_SUPPORT_1)}{' '}
                  <MailLink error href={`mailto:${supportMail}`}>
                    {supportMail}
                  </MailLink>
                  . {formatMessage(messages.DEFAULT_HINT_SUPPORT_2)}
                </li>
              ) : null}
            </List>
          </p>
        </Details>

        {error ? (
          <Details style={{ whiteSpace: 'pre-wrap' }}>
            <summary>{formatMessage(messages.DISPLAY_ERROR_MESSAGE)}</summary>
            <p>
              <code>{getString(error)}</code>
            </p>
          </Details>
        ) : null}
      </ErrorField>
    </div>
  );
};

export default connect((state) => ({
  supportMail: state.config.data && state.config.data.supportMail,
}))(injectIntl(ErrorComponent));
