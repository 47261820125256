import { combineReducers } from 'redux';

import { reducer as form } from 'redux-form';

import auth from './auth';
import intl from './intl';
import list from './list';
import config from './config';
import single from './single';
import machine from './machine';
import entity from './entity';
import websocket from './websocket';
import dashboards from './dashboard';
import fullscreen from './fullscreen';
import initialData from './initialData';
import search from './search';
import live_data from './liveData';

export default combineReducers({
  auth,
  form,
  intl,
  list,
  config,
  single,
  search,
  machine,
  entity,
  websocket,
  dashboards,
  fullscreen,
  initialData,
  live_data,
});
