import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import styled from 'styled-components';
import request from '../../api/request';
import CommonPageContainer from '../../components/common/CommonPageContainer';
import PasswordResetForm from './components/PasswordResetForm';
import { withHOCSRouter } from '../../components/hocs';

const TextsHolder = styled.div`
  max-width: 22rem;
`;

const messages = defineMessages({
  HEADER: {
    id: 'PasswordResetPage.HEADER',
    defaultMessage: 'Reset password',
  },
  TEXT: {
    id: 'PasswordResetPage.TEXT',
    defaultMessage:
      "Forgotten your password? Enter your email address below, and we'll email instructions for setting a new one.",
  },
  BACK_TO_LOGIN_LINK: {
    id: 'PasswordResetPage.BACK_TO_LOGIN_LINK',
    defaultMessage: 'Already have login and password?',
  },
});

const PasswordResetPage = ({ onSubmit, intl: { formatMessage } }) => (
  <CommonPageContainer isSecondary>
    <TextsHolder>
      <h1 className="tc fw5 mt3 mb4">{formatMessage(messages.HEADER)}</h1>
      <p>{formatMessage(messages.TEXT)}</p>
    </TextsHolder>
    <PasswordResetForm isSecondary onSubmit={onSubmit} />
    <Link className="self-center mt3 db tc ssf-blue link f7 underline-hover" to="/login/">
      {formatMessage(messages.BACK_TO_LOGIN_LINK)}
    </Link>
  </CommonPageContainer>
);

export default withHOCSRouter(
  connect(null, (dispatch, { navigate }) => ({
    onSubmit: async (data) => {
      try {
        await request.post({ url: '/password_reset/', data });
        navigate('/password_reset/done/');
      } catch (error) {
        throw new SubmissionError(error);
      }
    },
  }))(injectIntl(PasswordResetPage))
);
