import { injectIntl, defineMessages } from 'react-intl';
import queryString from 'query-string';
import dateMath from '../../../utils/dateMath';
import Button from '../../../components/common/Button';

const messages = defineMessages({
  SHIFT_TIMEFRAME_LEFT: {
    id: 'TimeRangeShiftBtns.SHIFT_TIMEFRAME_LEFT',
    defaultMessage: 'Shift timeframe to the left',
  },
});

function shiftTimeframeLeft(from, to, timezone_offset) {
  const fromDate = new Date(dateMath(from, timezone_offset)).getTime();
  const toDate = new Date(dateMath(to, timezone_offset)).getTime();
  const deltaT = Math.abs(toDate - fromDate);
  return { from: new Date(fromDate - deltaT).toISOString(), to: new Date(fromDate).toISOString() };
}

function navigate2({ navigate, location, query }) {
  navigate(`${location.pathname}?${queryString.stringify(query)}`);
}

function TimeRangeShiftBtns({
  navigate,
  location,
  from,
  to,
  timezone_offset,
  intl: { formatMessage },
}) {
  return (
    <Button
      title={formatMessage(messages.SHIFT_TIMEFRAME_LEFT)}
      icon="fa fa-chevron-left"
      onClick={() =>
        navigate2({ navigate, location, query: shiftTimeframeLeft(from, to, timezone_offset) })
      }
      marginRight
    />
  );
}

export default injectIntl(TimeRangeShiftBtns);
