import { handleActions } from 'redux-actions';
import {
  INITIAL_DATA_CLEAR,
  INITIAL_DATA_SET,
  INITIAL_DATA_ERROR,
  CREATE_ENTITY,
  UPDATE_ENTITY,
  DELETE_ENTITY,
} from '../actions/constants';

export default handleActions(
  {
    [INITIAL_DATA_SET]: (state, { payload: { entitiesObject } }) => ({
      ...state,
      ...entitiesObject,
    }),
    [INITIAL_DATA_CLEAR || INITIAL_DATA_ERROR]: () => ({}),
    [CREATE_ENTITY]: (state, { payload: { data } }) => ({
      ...state,
      [data.id]: { ...data },
    }),
    [UPDATE_ENTITY]: (state, { payload: { data } }) => {
      if (state[data.id]) {
        return {
          ...state,
          [data.id]: { ...data },
        };
      }
      return state;
    },
    [DELETE_ENTITY]: (state, { payload: { id, data } }) => {
      if (state[id]) {
        const newState = { ...state };
        delete newState[id];

        if (data && data.parent_id) {
          // update all entities with new parent_id
          Object.values(newState).forEach((e) => {
            if (e.parent_id === id) {
              newState[e.id].parent_id = data.parent_id;
            }
          });
        }

        return newState;
      }
      return state;
    },
  },
  {}
);
