import { defineMessages, injectIntl } from 'react-intl';

import Button from '../../components/common/Button';
import CommonPageContainer from '../../components/common/CommonPageContainer';

const messages = defineMessages({
  HEADER: {
    id: 'PasswordResetCompleteView.HEADER',
    defaultMessage: 'Reset complete',
  },
  TEXT: {
    id: 'PasswordResetCompleteView.TEXT',
    defaultMessage: 'Your password has been set. You may go ahead and log in now.',
  },
  BACK_TO_LOGIN_LINK: {
    id: 'PasswordResetCompleteView.BACK_TO_LOGIN_LINK',
    defaultMessage: 'Log in',
  },
});

const PasswordResetCompleteView = ({ intl: { formatMessage } }) => (
  <CommonPageContainer isSecondary>
    <h1 className="tc fw5 mt3 mb4">{formatMessage(messages.HEADER)}</h1>
    <p>{formatMessage(messages.TEXT)}</p>
    <Button
      large
      activestyle="primary"
      block
      className="tc"
      to="/login/"
      label={formatMessage(messages.BACK_TO_LOGIN_LINK)}
    />
  </CommonPageContainer>
);

export default injectIntl(PasswordResetCompleteView);
