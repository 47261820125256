import { createAction } from 'redux-actions';

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  INITIAL_DATA_CLEAR,
  INITIAL_DATA_SET,
  INITIAL_DATA_ERROR,
} from './constants';
import {
  loadInitialDashboardData,
  loadInitialMachineData,
  loadInitialEntityData,
} from '../api/initialData';

export const initialDataClear = createAction(INITIAL_DATA_CLEAR);
export const initialDataSet = createAction(INITIAL_DATA_SET);
export const initialDataError = createAction(INITIAL_DATA_ERROR);

// initialData redux middleware @see store.js
export default (store) => (next) => (action) => {
  const result = next(action);

  if (result.type === AUTH_LOGIN) {
    let dashboard_order = [];
    if (result.payload && result.payload.dashboard_order && result.payload.dashboard_order.length) {
      dashboard_order = result.payload.dashboard_order;
    }
    store.dispatch(initialDataClear());

    // load initial machine data async
    loadInitialMachineData().then(
      (initialData) => {
        store.dispatch(initialDataSet(initialData));
      },
      (err) => store.dispatch(initialDataError(err))
    );

    // load initial entity data async
    loadInitialEntityData().then(
      (initialData) => {
        store.dispatch(initialDataSet(initialData));
      },
      (err) => store.dispatch(initialDataError(err))
    );

    loadInitialDashboardData().then(
      (initialData) => {
        if (dashboard_order.length && initialData.dashboardList) {
          // user has ordered dashboards -> keep order
          const dashboardsInOrderedList = {};
          const newData = {};
          newData.dashboardList = dashboard_order.map((dashboardID) => {
            const db = initialData.dashboardList.find((dl) => dl && dl.id === dashboardID);
            if (db) {
              dashboardsInOrderedList[db.id] = true;
            }
            return db;
          });
          // check if there are dashboards which are not in ordered list and add them
          // e.g. newly created dashboards
          initialData.dashboardList.forEach((dl) => {
            if (!dashboardsInOrderedList[dl.id]) {
              newData.dashboardList.push(dl);
            }
          });

          store.dispatch(initialDataSet(newData));
        } else {
          store.dispatch(initialDataSet(initialData));
        }
      },
      (err) => store.dispatch(initialDataError(err))
    );
  } else if (result.type === AUTH_LOGOUT) {
    store.dispatch(initialDataClear());
  }

  return result;
};
