import Select from 'react-select';
import styled from 'styled-components';
// import AsyncSelect from 'react-select/async';

export const StyledSelect = styled(Select)`
  // -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  // -moz-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  // box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);

  & > .Select-control {
    min-width: 13rem;
    border: ${({ theme }) => theme.selectField.border} !important;
    background: ${({ theme }) => theme.selectField.background} !important;
  }
  & > .Select-control > .Select-multi-value-wrapper > .Select-value > .Select-value-label {
    color: ${({ theme }) => theme.selectField.color} !important;
  }
  & > .Select-control > .Select-multi-value-wrapper > .Select-input > input {
    color: ${({ theme }) => theme.selectField.color} !important;
  }
  & > .Select-menu-outer {
    // top: auto !important;
    // bottom: 100% !important;

    // -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    // -moz-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    // box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);

    border: ${({ theme }) => theme.selectField.border} !important;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.selectField.background} !important;
  }
  & > .Select-menu-outer > .Select-menu > .Select-option {
    background-color: transparent;
    color: ${({ theme }) => theme.selectField.color} !important;
  }
`;

// export const StyledSelectVirtualized = styled(VirtualizedSelect)`
//   & > .Select-control {
//     border: ${({ theme }) => theme.selectField.border} !important;
//     background: ${({ theme }) => theme.selectField.background} !important;
//   }
//   & > .Select-control > .Select-multi-value-wrapper > .Select-value > .Select-value-label {
//     color: ${({ theme }) => theme.selectField.color} !important;
//   }
//   & > .Select-control > .Select-multi-value-wrapper > .Select-input > input {
//     color: ${({ theme }) => theme.selectField.color} !important;
//   }
//   & > .Select-menu-outer {
//     border: ${({ theme }) => theme.selectField.border} !important;
//     border-radius: 3px !important;
//     background: ${({ theme }) => theme.selectField.background} !important;
//   }
//   & > .Select-menu-outer > .Select-menu > .Select-option {
//     background-color: transparent;
//     color: ${({ theme }) => theme.selectField.color} !important;
//   }
// `;

// export const StyledSelectAsync = styled(AsyncSelect)`
//   & > .Select-control {
//     border: ${({ theme }) => theme.selectField.border} !important;
//     background: ${({ theme }) => theme.selectField.background} !important;
//   }
//   & > .Select-control > .Select-multi-value-wrapper > .Select-input > input {
//     color: ${({ theme }) => theme.selectField.color} !important;
//   }
//   & > .Select-control > span > .Select-value > .Select-value-label {
//     color: ${({ theme }) => theme.selectField.color} !important;
//   }
//   & > .Select-menu-outer {
//     border: ${({ theme }) => theme.selectField.border} !important;
//     border-radius: 3px !important;
//     background: ${({ theme }) => theme.selectField.background} !important;
//   }
//   & > .Select-menu-outer > .Select-menu > .Select-option {
//     background-color: transparent;
//     color: ${({ theme }) => theme.selectField.color} !important;
//   }
// `;

// const SelectFieldReactSelectVersion2 = styled(Select)`
//   & > .Select-control__control {
//     border: ${({ theme }) => theme.selectField.border};
//     background: ${({ theme }) => theme.selectField.background};
//   }

//   & > .Select-control__control .Select-control__single-value {
//     color: ${({ theme }) => theme.selectField.color};
//   }

//   & > .Select-control__menu {
//     border: ${({ theme }) => theme.selectField.border};
//     border-radius: 3px;
//     background: ${({ theme }) => theme.selectField.background};
//     color: ${({ theme }) => theme.selectField.color};
//   }

//   & > .Select-control__option {
//     background-color: ${({ theme }) => theme.selectField.background};
//     color: ${({ theme }) => theme.selectField.color};
//   }

//   .Select-control__option--is-focused {
//     background-color: ${({ theme }) => theme.selectField.backgroundHighlight};
//     color: ${({ theme }) => theme.selectField.color};
//   }
// `;

export default StyledSelect;
