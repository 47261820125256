import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import request from '../../api/request';
import CommonPageContainer from '../../components/common/CommonPageContainer';
import UserRegistrationComponent from './components/UserRegistrationForm';
import { withHOCSRouter } from '../../components/hocs';

const messages = defineMessages({
  HEADER: {
    id: 'UserForm.HEADER',
    defaultMessage: 'Registration',
  },
  BACK_TO_LOGIN_LINK: {
    id: 'PasswordResetConfirmPage.BACK_TO_LOGIN_LINK',
    defaultMessage: 'Back to start page',
  },
});

const UserRegistrationPage = ({ supportedLanguages, onSubmit, language, intl: { formatMessage } }) => (
  <CommonPageContainer isSecondary isLogin>
    <h1 className="tc fw5 mt3 mb4">{formatMessage(messages.HEADER)}</h1>
    <UserRegistrationComponent onSubmit={onSubmit} supportedLanguages={supportedLanguages} language={language} />
    <Link className="self-center mt3 db tc ssf-blue link f7 underline-hover" to="/">
      {formatMessage(messages.BACK_TO_LOGIN_LINK)}
    </Link>
  </CommonPageContainer>
);

export default withHOCSRouter(
  injectIntl(
    connect(
      (state) => ({
        supportedLanguages: state.config.data.supportedLanguages || [],
        language: state.intl && state.intl.locale,
      }),
      (dispatch, { location, navigate, intl: { formatMessage } }) => ({
        onSubmit: async (data) => {
          try {
            await request.post({ url: location.pathname, data });
            toast.success(
              formatMessage({
                id: 'UserForm.SUCCESS',
                defaultMessage: 'Registration successful. Please check your email inbox',
              })
            );
            navigate('/login/');
          } catch (error) {
            if (error.message) {
              toast.error('Error: ' + error.message);
            }
            if (error._error) {
              toast.error('Error: ' + error._error);
            }
          }
        },
      })
    )(UserRegistrationPage)
  )
);
