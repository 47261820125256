import { PureComponent } from 'react';
import styled from 'styled-components';

import request from '../../../api/request';
import FieldWrapper from '../FieldWrapper';
import StyledSelect from '../../../controls/StyledSelect';
import { getValue } from '../../../utils/selectinput';

const Wrapper = styled(FieldWrapper)`
  background: ${({ theme }) => theme.inputField.background};
  padding: 0.3rem;
  box-shadow: ${({ theme }) => theme.div.boxShadow} !important;
  & > label {
    color: ${({ theme }) => theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
`;

class SelectTimezoneFieldTypeField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { options: [] };
    this.loadOptions = this.loadOptions.bind(this);
  }

  async loadOptions() {
    try {
      const response = await request.get({ url: '/timezone/' });
      this.setState(() => ({
        options: response.map((component) => ({
          value: component,
          label: component,
        })),
      }));
      // eslint-disable-next-line no-empty
    } catch {}
  }

  componentDidMount() {
    this.loadOptions();
  }

  render() {
    const {
      config: { label, label_not_found },
      input,
      meta,
    } = this.props;

    return (
      <Wrapper label={label} meta={meta}>
        <StyledSelect
          value={getValue(input.value, this.state.options)}
          isSearchable={true}
          onChange={(opt) => input.onChange(opt && opt.value)}
          options={this.state.options}
          onFocus={() => input.onFocus()}
          onBlur={() => input.onBlur()}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          noOptionsMessage={() => label_not_found}
        />
      </Wrapper>
    );
  }
}

export default SelectTimezoneFieldTypeField;
