import { handleActions } from 'redux-actions';

import { DOCUMENT_CREATED } from '../actions/constants';

const emptyState = { datasource_id: {} };

export default handleActions(
  {
    [DOCUMENT_CREATED]: (state, { payload: { id } }) => ({
      ...state,
      datasource_id: { [id]: +new Date() },
    }),
  },
  emptyState
);
