import classnames from 'classnames';

import styled from 'styled-components';

import FieldWrapper from './FieldWrapper';

const Wrapper = styled(FieldWrapper)`
  background: ${({ theme }) => theme.inputField.background};
  padding: 0.3rem;
  box-shadow: ${({ theme }) => theme.div.boxShadow} !important;
  & > label {
    color: ${({ theme }) => theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
`;

const WrapperNoShadow = styled(FieldWrapper)`
  background: ${({ theme }) => theme.inputField.background};
  padding: 0.3rem;
  & > label {
    color: ${({ theme }) => theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
`;

const InputField = styled.input`
  color: ${({ theme }) => theme.inputField.color};
  border: none;
  border: ${({ theme }) => theme.selectField.border} !important;
  border-radius: 0.2rem;
  background: none;
`;

export default function StringFieldComponent(props) {
  const { config, meta, input, no_shadow = false, fieldValue } = props;
  const hasError = meta.touched && meta.error;

  if (!input.value && fieldValue) {
    input.value = fieldValue.value;
  }

  const className = classnames('input-reset db bl-0 br-0 bt-0 w-100 mb1 pa2', {
    'b--ssf-red': hasError,
  });

  if (no_shadow) {
    return (
      <WrapperNoShadow name={input.name} label={config.label} meta={meta}>
        <InputField
          type={config.inputType || 'text'}
          className={className}
          placeholder={config.placeholder}
          id={`${input.name}-id`}
          // autoFocus screws up redux-form and does not load data sometimes on start
          // autoFocus={config.autoFocus}
          autoComplete={config.autoComplete}
          readOnly={config.readOnly}
          maxLength={config.maxLength}
          inputMode={config.inputMode}
          pattern={config.pattern}
          disabled={config.disabled}
          {...input}
        />
      </WrapperNoShadow>
    );
  }

  return (
    <Wrapper name={input.name} label={config.label} meta={meta}>
      <InputField
        type={config.inputType || 'text'}
        className={className}
        placeholder={config.placeholder}
        id={`${input.name}-id`}
        // autoFocus screws up redux-form and does not load data sometimes on start
        // autoFocus={config.autoFocus}
        autoComplete={config.autoComplete}
        readOnly={config.readOnly}
        maxLength={config.maxLength}
        inputMode={config.inputMode}
        pattern={config.pattern}
        disabled={config.disabled}
        {...input}
      />
    </Wrapper>
  );
}
