import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import classnames from 'classnames';

const StyledLabel = styled.label`
  color: ${({ theme }) => theme.inputField.label.color};
`;

function formatMessageOrNot(formatMessage, data) {
  if (typeof data === 'object' && data !== null) {
    return formatMessage(data);
  }
  return data;
}

export function FieldWrapperComponent({
  name,
  className,
  label,
  description,
  meta,
  children,
  intl: { formatMessage },
}) {
  const hasError = meta.touched && meta.error;
  return (
    <div className={classnames('mb3', className)}>
      {label ? ( // eslint-disable-next-line
        <StyledLabel
          className={classnames('db mb1 gray f6', { 'ssf-red': hasError })}
          htmlFor={`${name}-id`}
        >
          {formatMessageOrNot(formatMessage, label)}
        </StyledLabel>
      ) : null}
      <div className="mb1">{children}</div>
      {description ? <p className="mb1">{formatMessageOrNot(formatMessage, description)}</p> : null}
      {hasError && (
        <div className="db mb1 ssf-red">
          {typeof meta.error === 'object' ? JSON.stringify(meta.error) : meta.error}
        </div>
      )}
    </div>
  );
}

export default injectIntl(FieldWrapperComponent);
