import superagent from 'superagent';
import { getJWT } from '../utils/jwt';
import API_URL_BASE from './API_URL_BASE';

function callback(successCallback, errorCallback, returnResponse, error, response) {
  // if (response && response.status === 401) {
  //   localStorage.removeItem('token');
  // }

  if (error) {
    let errorMessage;
    if (error.response) {
      if (error.response.body && typeof error.response.body === 'object') {
        errorMessage = error.response.body;
        /* eslint-disable no-underscore-dangle */
        if (errorMessage.non_field_errors) {
          errorMessage._error = errorMessage.non_field_errors.join(' ');
          delete errorMessage.non_field_errors;
        }
        if (errorMessage.__all__) {
          errorMessage._error = errorMessage.__all__.join(' ');
          delete errorMessage.__all__;
        }
        if (errorMessage.detail && Object.keys(errorMessage).length === 1) {
          errorMessage._error = errorMessage.detail;
          delete errorMessage.detail;
        }
        /* eslint-enable no-underscore-dangle */
      } else if (error.response.body) {
        errorMessage = { _error: error.response.body };
      } else if (error.response.text) {
        errorMessage = { _error: error.response.text };
      } else {
        errorMessage = {
          _error: error.response.error && error.response.error.message,
        };
      }
    } else {
      errorMessage = { _error: error.message };
    }

    Object.keys(errorMessage).forEach((key) => {
      if (Array.isArray(errorMessage[key])) {
        errorMessage[key] = errorMessage[key].join(' ');
      }
    });

    /* eslint-enable no-underscore-dangle */
    errorMessage._status = response && response.status;

    errorCallback(errorMessage);
    return;
  }
  successCallback(returnResponse ? response : response.body);
}

function pureFetch(
  {
    url,
    method = 'get',
    query = {},
    data = {},
    attachments = {},
    headers = {},
    anonymous = false,
    returnResponse = false,
  },
  successCallback,
  errorCallback,
  progressCallback
) {
  if (!url) {
    throw new Error('url required!');
  }
  let request = superagent[method](`${API_URL_BASE}${url}`);

  request = request.query(query);

  if (Object.keys(data).length) {
    request = request.send(data);
  }

  Object.keys(attachments)
    .filter((attachmentName) => attachments[attachmentName])
    .forEach((attachmentName) => {
      request = request.attach(attachmentName, attachments[attachmentName]);
    });

  if (!anonymous && !headers.Authorization) {
    const token = getJWT();
    if (token) {
      request = request.set('Authorization', `Bearer ${token}`);
    }
  }

  const websocket_id = sessionStorage.getItem('websocket_id');
  if (websocket_id) {
    request = request.set('X-Websocket-ID', websocket_id);
  }
  request = request.set('Accept', 'application/json');
  request = request.set(headers);

  if (progressCallback) {
    request.on('progress', progressCallback);
  }

  return request.end(callback.bind(null, successCallback, errorCallback, returnResponse));
}

function upload(
  { url, method = 'post', file, anonymous = false },
  successCallback,
  errorCallback,
  progressCallback
) {
  if (!url) {
    throw new Error('url required!');
  }
  let request = superagent[method](`${API_URL_BASE}${url}`);

  const formData = new FormData();
  formData.append('file', file);

  request = request
    // .set('Content-Type', file.type)
    .set('Content-Disposition', `attachment; filename=${file.name}`)
    // .set('Content-Length', file.size)
    .send(formData);

  if (!anonymous) {
    const token = getJWT();
    if (token) {
      request = request.set('Authorization', `Bearer ${token}`);
    }
  }

  if (progressCallback) {
    request.on('progress', progressCallback);
  }

  return request.end(callback.bind(null, successCallback, errorCallback, false));
}

function fetch(config) {
  return new Promise((resolve, reject) => pureFetch(config, resolve, reject));
}

function get(config) {
  return fetch({ ...config, method: 'get' });
}

function post(config) {
  return fetch({ ...config, method: 'post' });
}

function put(config) {
  return fetch({ ...config, method: 'put' });
}

function patch(config) {
  return fetch({ ...config, method: 'patch' });
}

function del(config) {
  return fetch({ ...config, method: 'del' });
}

export default {
  upload,
  pureFetch,
  fetch,
  get,
  post,
  put,
  patch,
  del,
};
