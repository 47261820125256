import { injectIntl, defineMessages } from 'react-intl';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import request from '../../api/request';
import { profileUpdated } from '../../actions/auth';

import { Section, Header, Main } from '../../components/page';
import Button from '../../components/common/Button';
import MFAForm from './MFAForm';

const messages = defineMessages({
  HEADER: {
    id: 'MFAForm.HEADER',
    defaultMessage: 'Two Factor Authentication',
  },
  BUTTON_BACK: {
    id: 'AccountSinglePage.BUTTON_BACK',
    defaultMessage: 'Back',
  },
  SUCCESS: {
    id: 'MFAForm.SUCCESS',
    defaultMessage: 'Two Factor Authentication has been enabled/disabled',
  },
});

function submitRequest(data) {
  if (data.mfa_enabled) {
    return request.del({ url: '/mfa', data: { mfa_passcode: data.mfa_passcode } });
  }

  return request.post({ url: '/mfa', data });
}

function CreateMFAPage({ user, onSubmit, onCancel, intl: { formatMessage } }) {
  return (
    <Section>
      <Button
        label={formatMessage(messages.BUTTON_BACK)}
        icon="fa fa-arrow-left"
        to={'/profile/'}
      />
      <Header text={formatMessage(messages.HEADER)} />
      <Main>
        <MFAForm onSubmit={onSubmit} onCancel={onCancel} user={user} />
      </Main>
    </Section>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      user: state.auth.user,
    }),
    (dispatch, { navigate, intl: { formatMessage } }) => ({
      onSubmit: (data) =>
        submitRequest(data).then(
          (user) => {
            dispatch(profileUpdated({ data: user }));
            toast.success(formatMessage(messages.SUCCESS));
            navigate('/profile/');
          },
          (error) => {
            if (error && error._error) {
              toast.error(error._error);
            }
          }
        ),
      onCancel: () => navigate('/profile/'),
    })
  )(injectIntl(CreateMFAPage))
);
