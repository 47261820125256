import { handleActions } from 'redux-actions';

import { WEBSOCKET_ONLINE, WEBSOCKET_OFFLINE, WEBSOCKET_MESSAGE } from '../actions/constants';

export default handleActions(
  {
    [WEBSOCKET_ONLINE]: () => true,
    [WEBSOCKET_OFFLINE]: () => false,
    [WEBSOCKET_MESSAGE]: (_, action) => {
      if (action.payload && action.payload.id && action.payload.id !== '') {
        sessionStorage.setItem('websocket_id', action.payload.id);
      }
      return null;
    },
  },
  false
);
