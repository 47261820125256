import styled from 'styled-components';

import { batch, connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import { defineMessages, injectIntl } from 'react-intl';
import Button from '../../../components/common/Button';

const ButtonSelection = styled(Button)`
  color: ${({ theme }) => theme.dataButtons.color};
  background: ${({ theme }) => theme.dataButtons.background};
`;

const messages = defineMessages({
  REALTIME: {
    id: 'TimeRangeSelectorQuickRanges.REALTIME',
    defaultMessage: 'Realtime view',
  },
  HOUR: {
    id: 'TimeRangeSelectorQuickRanges.HOUR',
    defaultMessage: 'Current hour (00 until 59)',
  },
  LASTHOUR: {
    id: 'TimeRangeSelectorQuickRanges.LASTHOUR',
    defaultMessage: 'Last hour',
  },
  TODAY: {
    id: 'TimeRangeSelectorQuickRanges.TODAY',
    defaultMessage: 'Today (00:00 until 23:59)',
  },
  YESTERDAY: {
    id: 'TimeRangeSelectorQuickRanges.YESTERDAY',
    defaultMessage: 'Yesterday',
  },
  WEEK: {
    id: 'TimeRangeSelectorQuickRanges.WEEK',
    defaultMessage: 'Current week',
  },
  LASTWEEK: {
    id: 'TimeRangeSelectorQuickRanges.LASTWEEK',
    defaultMessage: 'Last week',
  },
  MONTH: {
    id: 'TimeRangeSelectorQuickRanges.MONTH',
    defaultMessage: 'Current month',
  },
  LASTMONTH: {
    id: 'TimeRangeSelectorQuickRanges.LASTMONTH',
    defaultMessage: 'Last month',
  },
  YEAR: {
    id: 'TimeRangeSelectorQuickRanges.YEAR',
    defaultMessage: 'Current year',
  },
  LASTYEAR: {
    id: 'TimeRangeSelectorQuickRanges.LASTYEAR',
    defaultMessage: 'Last year',
  },
  LAST_THREE_DAYS: {
    id: 'TimeRangeSelectorQuickRanges.LAST_THREE_DAYS',
    defaultMessage: 'Last 3 days',
  },
});

const QUICK_RANGES = [
  {
    label: messages.REALTIME,
    from: 'now-30m',
    to: 'now+5m',
  },
  {
    label: messages.HOUR,
    from: 'now/h',
    to: 'now/h+1h',
  },
  {
    label: messages.LASTHOUR,
    from: 'now/h-1h',
    to: 'now/h',
  },
  {
    label: messages.TODAY,
    from: 'now/d',
    to: 'now/d+1d',
  },
  {
    label: messages.YESTERDAY,
    from: 'now/d-1d',
    to: 'now/d',
  },
  {
    label: messages.LAST_THREE_DAYS,
    from: 'now/d-3d',
    to: 'now/d+1d',
  },
  {
    label: messages.WEEK,
    from: 'now/w',
    to: 'now/w+1w',
  },
  {
    label: messages.LASTWEEK,
    from: 'now/w-1w',
    to: 'now/w',
  },
  {
    label: messages.MONTH,
    from: 'now/M',
    to: 'now/M+1M',
  },
  {
    label: messages.LASTMONTH,
    from: 'now/M-1M',
    to: 'now/M',
  },
  {
    label: messages.YEAR,
    from: 'now/y',
    to: 'now/y+1y',
  },
  {
    label: messages.LASTYEAR,
    from: 'now/y-1y',
    to: 'now/y',
  },
];

function TimeRangeSelectorQuickRanges({ from, to, changeFormValues, intl: { formatMessage } }) {
  return (
    <div>
      {QUICK_RANGES.map((item) => (
        <ButtonSelection
          marginRight
          marginBottom
          key={item.label.id}
          onClick={() => changeFormValues(item)}
          label={formatMessage(item.label)}
          style={{
            color: from === item.from && to === item.to ? '#fff' : '',
            background: from === item.from && to === item.to ? '#2792d6' : '',
          }}
        />
      ))}
    </div>
  );
}

export default connect(
  (state) => getFormValues('timeRangeSelector')(state) || {},
  (dispatch) => ({
    changeFormValues: ({ from, to }) => {
      batch(() => {
        dispatch(change('timeRangeSelector', 'from', from));
        dispatch(change('timeRangeSelector', 'to', to));
      });
    },
  })
)(injectIntl(TimeRangeSelectorQuickRanges));
