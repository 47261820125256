import { useSortable } from '@dnd-kit/sortable';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CSS } from '@dnd-kit/utilities';
import { Dashboard } from '@material-ui/icons';
import classnames from 'classnames';

const HoldingDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0 0.5rem 1.2rem;
  text-decoration: none;
  &:hover {
    background: ${({ theme }) => theme.secondaryDiv.backgroundHover};
  }
`;

const DashboardIcon = styled(Dashboard)`
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.secondaryDiv.color};
  cursor: move;
  outline: none;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

const LinkConn = styled(Link)`
  width: 100%;
  padding: 0.5rem 0 0.5rem 1.2rem;
  text-decoration: none;
  color: ${({ theme }) => theme.secondaryDiv.color};
`;

export function DashboardItemDrag(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { opt, current_dashboard_id, removeExternalDashboard } = props;

  return (
    <HoldingDiv ref={setNodeRef} style={style} key={opt.id} className="flex justify-between">
      {<DashboardIcon {...listeners} {...attributes} />}
      <LinkConn
        to={`${opt.path}`}
        state={{ currentDashboard: opt.object }}
        title={opt.description ? opt.description : null}
        onClick={() => props.sidebarToggle()}
      >
        <span className={classnames({ green: opt.id === current_dashboard_id })}>
          {opt.label}
          {opt.external_user_name ? (
            <>
              <br />
              <small className="light-red">{opt.external_user_name}</small>
            </>
          ) : null}
        </span>
      </LinkConn>
      {opt.is_external && opt.id !== current_dashboard_id ? (
        <div className="pa2 w-5 pointer" onClick={() => removeExternalDashboard(opt.id)}>
          x
        </div>
      ) : null}
    </HoldingDiv>
  );
}
