import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';

import request from '../../api/request';

import CommonPageContainer from '../../components/common/CommonPageContainer';
import Fetch from '../../components/Fetch';
import SetPasswordForm from './components/SetPasswordForm';
import { withHOCSRouter } from '../../components/hocs';

const messages = defineMessages({
  HEADER: {
    id: 'PasswordResetConfirmPage.HEADER',
    defaultMessage: 'Change password',
  },
  TEXT: {
    id: 'PasswordResetConfirmPage.TEXT',
    defaultMessage:
      'Please enter your new password twice so we can verify you typed it in correctly.',
  },
  BACK_TO_LOGIN_LINK: {
    id: 'PasswordResetConfirmPage.BACK_TO_LOGIN_LINK',
    defaultMessage: 'Back to start page',
  },
  INVALID_TOKEN: {
    id: 'PasswordResetConfirmPage.INVALID_TOKEN',
    defaultMessage:
      'The password reset link was invalid, possibly because it has already been used.  Please request a new password reset.',
  },
});

const PasswordResetConfirmPage = ({ location, onSubmit, intl: { formatMessage } }) => (
  <CommonPageContainer isSecondary isLogin>
    <Fetch
      url={location.pathname}
      renderError={() => <p>{formatMessage(messages.INVALID_TOKEN)}</p>}
    >
      {() => (
        <>
          <h1 className="tc fw5 mt3 mb4">{formatMessage(messages.HEADER)}</h1>
          <p>{formatMessage(messages.TEXT)}</p>
          <SetPasswordForm onSubmit={onSubmit} />
          <Link className="self-center mt3 db tc ssf-blue link f7 underline-hover" to="/">
            {formatMessage(messages.BACK_TO_LOGIN_LINK)}
          </Link>
        </>
      )}
    </Fetch>
  </CommonPageContainer>
);

export default withHOCSRouter(
  connect(null, (dispatch, { location, navigate }) => ({
    onSubmit: async (data) => {
      try {
        await request.post({ url: location.pathname, data });
        navigate('/reset/done/');
      } catch (error) {
        throw new SubmissionError(error);
      }
    },
  }))(injectIntl(PasswordResetConfirmPage))
);
