export default (settings) => ({
  color: settings.color,
  background: settings.background,
  secondaryColor: settings.secondaryColor,
  secondaryBackground: settings.secondaryBackground,
  mainPageColor: settings.mainPageColor,
  mainPageBackground: settings.mainPageBackground,
  mainBoxshadow: settings.mainBoxshadow,
  lineHeight: '1.5em',
  padding: '0.75em 0.5em',
  height: 'auto',
  successColor: settings.successIndicator,
  errorColor: settings.errorIndicator,

  dashboardsBackground: settings.dashboardBackground,
  dashboardsColor: settings.dashboardsColor,
  blendSVG: settings.blendingSVG,

  mainPage: {
    backgroundImage: `${settings.backgroundImage ? settings.backgroundImage : null}`,
    backgroundRepeat: `${settings.backgroundRepeat ? settings.backgroundRepeat : null}`,
    backgroundPosition: `${settings.backgroundPosition ? settings.backgroundPosition : null}`,
    backgroundSize: `${settings.backgroundSize ? settings.backgroundSize : null}`,
  },

  widget: {
    color: settings.widgetColor,
    background: settings.widgetBackground,
    border: settings.widgetBorder,
    boxShadow: settings.lightBoxshadow,
    hover: settings.widgetHover,
  },
  div: {
    color: settings.color,
    background: settings.divBackground,
    boxShadow: settings.lightBoxshadow,
    border: settings.divBorder,
    backgroundHover: `${settings.divHover} !important`,
  },
  secondaryDiv: {
    color: settings.secondaryDivColor,
    background: settings.secondaryDivBackground,
    boxShadow: settings.mainBoxshadow,
    border: settings.secondaryDivBorder,
    backgroundHover: `${settings.secondaryDivHover} !important`,
  },
  mainPageDiv: {
    color: settings.mainPageDivColor,
    background: settings.mainPageDivBackground,
    boxShadow: settings.mainPageBoxshadow,
    border: settings.mainPageDivBorder,
    backgroundHover: `${settings.mainPageDivHover} !important`,
  },
  dataButtons: {
    color: settings.color,
    background: settings.divBackground,
    activeColor: 'red',
  },
  proplist: {
    color: settings.proplistNumbers,
  },
  link: {
    color: settings.color,
  },
  header: {
    color: settings.color,
    background: settings.headerBackground,
    borderBottom: settings.headerBorder,
    boxShadow: settings.mainBoxshadow,
  },
  list: {
    color: settings.color,
    background: settings.divBackground,
    borderBottom: settings.divBorder,
    link: {
      color: settings.color,
      backgroundHover: settings.divHover,
    },
  },
  loginField: {
    color: settings.mainPageColor,
    backgroundColor: settings.loginAccent,
    border: settings.mainPageDivBorder,
  },
  inputField: {
    color: settings.color,
    background: settings.divBackground,
    border: 'none',
    label: {
      color: settings.color,
    },
  },
  inputFieldSecondary: {
    color: settings.mainPageDivColor,
    background: settings.mainPageDivBackground,
    border: 'none',
    label: {
      color: settings.mainPageColor,
    },
  },
  errorField: {
    color: settings.color,
    infoColor: settings.mainColor,
    backgroundColor: settings.divBackground,
    border: settings.divBorder,
    boxShadow: settings.lightBoxshadow,
  },
  errorComponent: {
    backgroundErrorColor: settings.backgroundErrorColor,
    backgroundInfoColor: settings.backgroundInfoColor,
    backgroundInfoNonTransparentColor: settings.backgroundInfoNonTransparentColor,
    errorColor: settings.errorColor,
    infoColor: settings.infoColor,
    borderErrorColor: settings.borderErrorColor,
    borderInfoColor: settings.borderInfoColor,
    mailErrorColor: settings.mailErrorColor,
    mailInfoColor: settings.mailInfoColor,
  },
  memberField: {
    color: settings.color,
    backgroundColor: settings.divBackground,
    border: 'none',
    backgroundHover: `${settings.divHover} !important`,
    boxShadow: settings.lightBoxshadow,
  },
  mainText: {
    color: settings.mainColor,
  },
  logo: {
    filter: settings.invertFilter,
  },
  widgetFields: {
    filter: settings.invertFilter,
    background: settings.divBackground,
    backgroundHover: settings.divHover,
    color: settings.color,
  },
  table: {
    header: {
      background: settings.divHover,
    },
  },
  selectField: {
    color: settings.color,
    background: settings.divBackground,
    backgroundHighlight: settings.divHover,
    border: settings.divBorder,
  },
  linkText: {
    color: settings.linkText,
  },
  button: {
    default: {
      backgroundColor: settings.default,
      color: settings.defaultButtonText,
      backgroundHover: settings.defaultAccent,
      boxShadow: settings.defaultShadow,
    },
    secondary: {
      backgroundColor: settings.secondary,
      color: settings.secondaryButtonText,
      backgroundHover: settings.secondaryAccent,
      boxShadow: settings.secondaryShadow,
    },
    primary: {
      backgroundColor: settings.primary,
      color: settings.buttonText,
      backgroundHover: settings.primaryAccent,
      boxShadow: settings.primaryShadow,
    },
    danger: {
      backgroundColor: settings.danger,
      color: settings.buttonText,
      backgroundHover: settings.dangerAccent,
      boxShadow: settings.dangerShadow,
    },
    success: {
      backgroundColor: settings.success,
      color: settings.buttonText,
      backgroundHover: settings.successAccent,
      boxShadow: settings.successShadow,
    },
    input: {
      background: settings.divBackground,
      border: settings.divBorder,
    },
  },
});
