import isNil from 'lodash/isNil';
import { memoize } from 'proxy-memoize';
import { datasourceSelector } from './selectors';

export function getDevicesReduced(
  machines,
  entity_filter = [],
  device_filter = [],
  as_array = false,
  last_seen = false,
  current_state = false
) {
  if (!machines) {
    // eslint-disable-next-line no-param-reassign
    machines = {};
  }
  const devices = {};
  Object.keys(machines)
    .filter((device_id) => {
      if (entity_filter && entity_filter.length) {
        if (entity_filter.includes(machines[device_id].entity_id)) {
          return true;
        }
        return false;
      }
      return true;
    })
    .filter((device_id) => {
      if (device_filter && device_filter.length) {
        if (device_filter.includes(machines[device_id].id)) {
          return true;
        }
        return false;
      }
      return true;
    })
    .forEach((device_id) => {
      if (machines[device_id]) {
        devices[device_id] = {
          id: device_id,
          schema: machines[device_id].schema,
          datasources: machines[device_id].datasources,
          has_mms: machines[device_id].has_mms,
          entity_id: machines[device_id].entity_id,
          facility: machines[device_id].facility,
          hall: machines[device_id].hall,
          region: machines[device_id].region,
          location: machines[device_id].location,
          name: machines[device_id].name,
          internal_name: machines[device_id].internal_name,
          data: machines[device_id].data,
          config: machines[device_id].config,
        };
        if (last_seen) {
          devices[device_id].last_seen = machines[device_id].last_seen;
        }
        if (current_state) {
          devices[device_id].current_state = machines[device_id].current_state;
        }
      }
    });

  if (as_array) {
    return Object.values(devices);
  }

  return devices;
}

export const memoizeIsLoading = memoize((state) => isNil(state.machine));
export const memoizeSearch = memoize((state) => ({
  ...state.search,
  timezone: state.auth.user.timezone,
}));
export const memoizeDatasource = memoize(([state, props]) => datasourceSelector(state, props));
