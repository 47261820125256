import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import md5 from 'md5';
import NotFound from '../../components/NotFound';
import Loading from '../../components/Loading';
import { memoizeIsLoading } from '../../components/widget/widgets/utils/memoize';

function MachineFindPage({ machines, isLoading }) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  if (isLoading) {
    return <Loading />;
  }

  let deviceId = params.deviceId;

  if (params.efentoId) {
    if (params.efentoId.length === 4) {
      // last 4 digits of efento
      const efentoDevice =
        machines &&
        Object.values(machines).find(
          (m) =>
            m &&
            m.internal_name &&
            m.internal_name.startsWith('282C02') &&
            m.internal_name.endsWith(String(params.efentoId).toUpperCase())
        );
      if (efentoDevice) {
        deviceId = efentoDevice.id;
      }
    } else {
      deviceId = md5(String(params.efentoId).toUpperCase());
    }
  }

  // remove hyphens from deviceId
  deviceId = deviceId.replace(/-/g, '');
  if (machines && machines[deviceId]) {
    navigate(`/e/${machines[deviceId].entity_id}/d/${deviceId}/`);
  }

  return <NotFound pathname={location.pathname} />;
}

export default connect((state) => ({
  isLoading: memoizeIsLoading(state),
  machines: state.machine,
}))(MachineFindPage);
