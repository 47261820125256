import request from './request';

export function listDashboard() {
  return request.get({ url: '/dashboard/' });
}
export function createDashboard(data, user_id) {
  if (user_id) {
    return request.post({ url: '/dashboard/', data, headers: { 'X-User-ID': user_id } });
  }
  return request.post({ url: '/dashboard/', data });
}
export function getDashboard(id) {
  return request.get({ url: `/dashboard/${id}/` });
}
export function getDashboardSharedUsers(id) {
  return request.get({ url: `/dashboard/${id}/shared_users` });
}
export function updateDashboardSharedUsers(id, data) {
  return request.post({ url: `/dashboard/${id}/shared_users`, data });
}
// NOT USED
// export function updateDashboard(id, data) {
//   return request.put({ url: `/dashboard/${id}/`, data });
// }
export function patchDashboard(id, data, user_id) {
  if (user_id) {
    return request.patch({ url: `/dashboard/${id}/`, data, headers: { 'X-User-ID': user_id } });
  }
  return request.patch({ url: `/dashboard/${id}/`, data });
}
export function copyDashboard(id, user_id) {
  if (user_id) {
    return request.post({
      url: `/dashboard/${id}/copy`,
      headers: { 'X-User-ID': user_id },
      returnResponse: true,
    });
  }
  return request.post({ url: `/dashboard/${id}/copy`, returnResponse: true });
}
export function deleteDashboard(id) {
  return request.del({ url: `/dashboard/${id}/` });
}
// NOT USED
// export function listWidgets() {
//   return request.get({ url: '/widget/' });
// }
export function createWidget(data, user_id, is_copy = false) {
  if (user_id) {
    return request.post({
      url: '/widget/',
      data,
      headers: { 'X-User-ID': user_id, 'X-Copy': is_copy },
    });
  }
  if (is_copy === true) {
    return request.post({ url: '/widget/', data, headers: { 'X-Copy': is_copy } });
  }
  return request.post({ url: '/widget/', data });
}
// NOT USED
// export function getWidget(id) {
//   return request.get({ url: `/widget/${id}/` });
// }
export function updateWidget(id, data, user_id) {
  if (user_id) {
    return request.put({ url: `/widget/${id}/`, data, headers: { 'X-User-ID': user_id } });
  }
  return request.put({ url: `/widget/${id}/`, data });
}
export function deleteWidget(id, user_id) {
  if (user_id) {
    return request.del({ url: `/widget/${id}/`, headers: { 'X-User-ID': user_id } });
  }
  return request.del({ url: `/widget/${id}/` });
}
