import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Loadable from '../components/Loadable';
import InitialData from '../components/InitialData';
import NonDashboardNavigationPage from './NonDashboardNavigationPage';
import { isDESMA } from '../utils/desma';
import { DISTRIBUTOR, hasEntityTypeNonRecursive } from '../utils/entity';
import ErrorBoundary from '../components/ErrorBoundary';
import { withHOCSRouter } from '../components/hocs';
import Loading from '../components/Loading';

const DashboardIndexPage = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ './dashboard/DashboardIndexPage'),
  webpackRequireWeakId: () => require.resolveWeak('./dashboard/DashboardIndexPage.js'),
});

const NavigationPage = (props) => {
  if (props.isLoading) {
    return <Loading />;
  }
  return (
    <ErrorBoundary>
      {/* {!props.is_desma ? <PrivacyModal {...props} /> : null} // disabled because of no longer needed */}
      <InitialData key="initialdata">
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" state={props.location.state} />} />
          <Route
            path="/dashboard"
            element={
              <Navigate
                to={
                  props.initial_dashboard_id
                    ? `/dashboard/${props.initial_dashboard_id}`
                    : '/dashboard/list'
                }
                state={props.location.state}
              />
            }
          />
          <Route path="/dashboard/*" element={<DashboardIndexPage {...props} />} />
          <Route path="*" element={<NonDashboardNavigationPage />} />
        </Routes>
      </InitialData>
    </ErrorBoundary>
  );
};

export default withHOCSRouter(
  connect((state) => ({
    is_desma: isDESMA(state.config),
    isLoading: isEmpty(state.dashboards),
    is_distributor: hasEntityTypeNonRecursive(state.entity, DISTRIBUTOR),
    initial_dashboard_id:
      !isEmpty(state.dashboards) && Object.keys(state.dashboards).find((d) => state.dashboards[d]),
  }))(injectIntl(NavigationPage))
);
