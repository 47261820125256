import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import CommonPageContainer from '../../components/common/CommonPageContainer';

const messages = defineMessages({
  HEADER: {
    id: 'PasswordResetDonePage.HEADER',
    defaultMessage: 'Passwort reset',
  },
  TEXT1: {
    id: 'PasswordResetDonePage.TEXT1',
    defaultMessage:
      'We have emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.',
  },
  TEXT2: {
    id: 'PasswordResetDonePage.TEXT2',
    defaultMessage:
      "If you don't receive an email, please make sure you have entered the address you registered with, and check your spam folder.",
  },
  BACK_TO_LOGIN_LINK: {
    id: 'PasswordResetDonePage.BACK_TO_LOGIN_LINK',
    defaultMessage: 'Back to start page',
  },
});

const PasswordResetDonePage = ({ intl: { formatMessage } }) => (
  <CommonPageContainer isSecondary isLogin>
    <h1 className="tc fw5 mt3 mb4">{formatMessage(messages.HEADER)}</h1>
    <p>{formatMessage(messages.TEXT1)}</p>
    <p>{formatMessage(messages.TEXT2)}</p>
    <Link className="self-center mt3 db tc ssf-blue link f7 underline-hover" to="/">
      {formatMessage(messages.BACK_TO_LOGIN_LINK)}
    </Link>
  </CommonPageContainer>
);

export default injectIntl(PasswordResetDonePage);
