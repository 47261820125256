import buildTheme from './buildTheme';

const settings = {
  // Main colors
  color: '#dddddd',
  background: '#171717',

  secondaryColor: '#dddddd',
  secondaryBackground: '#171717',

  blendingSVG: 'normal',
  dashboardsColor: '#dddddd',
  dashboardBackground: '#002f4d',

  widgetColor: '#dddddd',
  widgetBackground: '#002f4d',
  widgetBorder: '1px solid #01243a',
  widgetHover: '#01243a',

  // Header colors
  headerBackground: '#002f4d',
  headerBorder: '1px solid #e9046c',

  // divs colors
  divBackground: '#002f4d',
  divBorder: '1px solid #01243a',
  divHover: '#01243a',

  secondaryDivBackground: '#002f4d',
  secondaryDivBorder: '1px solid #01243a',
  secondaryDivHover: '#01243a',
  secondaryDivColor: '#dddddd',

  linkText: '#e9046c',

  proplistNumbers: '#519dd4',

  // Button colors
  buttonText: '#ffffff',

  defaultButtonText: '#ffffff',
  default: '#002f4d',
  defaultAccent: '#01243a',
  defaultShadow: 'none',

  secondaryButtonText: '#ffffff',
  secondary: '#002f4d',
  secondaryAccent: '#01243a',
  secondaryShadow: 'none',

  primary: '#e9046c',
  primaryAccent: '#c8256c',
  primaryShadow: 'none',

  danger: '#e84c4d',
  dangerAccent: '#d85a56',
  dangerShadow: 'none',

  success: '#019958',
  successAccent: '#009863',
  successShadow: 'none',

  gray1: '#1B1E20',
  gray2: '#2D3033',
  gray3: '#31373C',
  gray4: '#394147',
  gray5: '#485158',
  gray6: '#4f5b63',

  mainColor: '#ffffff',

  // colors for user feedback
  errorIndicator: '#e84c4d',
  successIndicator: '#019958',

  // Img filters
  invertFilter: 'brightness(0) invert(.8)',

  // Img filters
  mainBoxshadow: '0px 2px 5px 0px rgba(0,0,0,0.5)',
  lightBoxshadow: '0px 2px 5px 0px rgba(0,0,0,0.05)',
  mainPageBoxshadow: 'none',

  // Error related
  backgroundErrorColor: '#ffbebf',
  backgroundInfoColor: 'transparent',
  backgroundInfoNonTransparentColor: '#00344c',
  errorColor: '#e54c4c',
  infoColor: '#3763a2',
  borderErrorColor: '1px solid #e54c4c',
  borderInfoColor: '0px solid #3763a2',
  mailErrorColor: '#e54c4c',
  mailInfoColor: '#3763a2',
};

export default buildTheme(settings);
