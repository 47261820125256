import classnames from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';

import convertDashboardToTemplate from '../../utils/convertDashboardToTemplate';

const ButtonField = styled.button`
  color: ${({ theme, activestyle }) =>
    activestyle ? theme.button[activestyle].color : theme.button.default.color};
  background: ${({ theme, activestyle }) =>
    activestyle ? theme.button[activestyle].backgroundColor : theme.button.default.backgroundColor};
  box-shadow: ${({ theme, activestyle, flat }) =>
    flat ? 'none' : theme.button[activestyle].boxShadow};
  &:hover {
    background: ${({ theme, activestyle }) =>
      activestyle
        ? theme.button[activestyle].backgroundHover
        : theme.button.default.backgroundHover};
  }
`;
const AnchorField = styled.button`
  color: ${({ theme, activestyle }) =>
    activestyle ? theme.button[activestyle].color : theme.button.default.color};
  background: ${({ theme, activestyle }) =>
    activestyle ? theme.button[activestyle].backgroundColor : theme.button.default.backgroundColor};
  box-shadow: ${({ theme, activestyle, flat }) =>
    flat ? 'none' : theme.button[activestyle].boxShadow};
  &:hover {
    background: ${({ theme, activestyle }) =>
      activestyle
        ? theme.button[activestyle].backgroundHover
        : theme.button.default.backgroundHover};
  }
`;
const LinkField = styled(Link)`
  color: ${({ theme, activestyle }) =>
    activestyle ? theme.button[activestyle].color : theme.button.default.color};
  background: ${({ theme, activestyle }) =>
    activestyle ? theme.button[activestyle].backgroundColor : theme.button.default.backgroundColor};
  box-shadow: ${({ theme, activestyle, flat }) =>
    flat ? 'none' : theme.button[activestyle].boxShadow};
  &:hover {
    background: ${({ theme, activestyle }) =>
      activestyle
        ? theme.button[activestyle].backgroundHover
        : theme.button.default.backgroundHover};
  }
`;

export function ButtonComponent({ children, activestyle, ...rest }) {
  return (
    <ButtonField activestyle={activestyle} {...rest}>
      {children}
    </ButtonField>
  );
}
export function AnchorComponent({ children, activestyle, ...rest }) {
  return (
    <AnchorField activestyle={activestyle} {...rest}>
      {children}
    </AnchorField>
  );
}
export function LinkComponent({ children, activestyle, ...rest }) {
  return (
    <LinkField activestyle={activestyle} {...rest}>
      {children}
    </LinkField>
  );
}

const downloadFile = (download, href, convert, data) => {
  const formatedData = convert ? convertDashboardToTemplate(data) : data;

  if (formatedData) {
    const blob = new Blob([JSON.stringify(formatedData, null, 2)], { type: 'application/json' });
    saveAs(blob, download);
  } else {
    const link = document.createElement('a');
    link.download = download;
    link.href = href;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const isMail = (text) => {
  if (!text) {
    return false;
  }
  return text.includes('mailto:');
};

function handleOnClick(href, download, convert, data, target = '_self', e) {
  if (download) {
    downloadFile(download, href, convert, data);
  } else if (isMail(href)) {
    e.preventDefault();
    window.open(href, target);
    return false;
  }

  return true;
}

export default function Button(props) {
  const {
    marginRight,
    marginBottom,
    marginLeft,
    marginTop,
    primary,
    danger,
    icon,
    activestyle = 'default',
    label = '',
    flat,
    component,
    className: originalClassName,
    block,
    success,
    large,
    convert = false,
    login = false,
    ...rest
  } = props;
  const notSmallLabel = rest['label-ns'];
  delete rest['label-ns'];

  let className;
  // if (!flat) {
  //   className += ' box-shadow-ssf-gray2';
  // }

  // if (primary) {
  //   className = 'white bg-primary hover-bg-primary';

  //   if (!flat) {
  //     className += ' box-shadow-ssf-blue';
  //   }
  // }

  // if (success) {
  //   className = 'white bg-ssf-green hover-bg-ssf-green';
  //   if (!flat) {
  //     className += ' box-shadow-ssf-green';
  //   }
  // }

  // if (danger) {
  //   className = 'white bg-ssf-red hover-bg-ssf-red';
  //   if (!flat) {
  //     className += ' box-shadow-ssf-red';
  //   }
  // }

  if (marginRight) {
    className += ' mr2';
  }
  if (marginBottom) {
    className += ' mb2';
  }
  if (marginTop) {
    className += ' mt2';
  }
  if (marginLeft) {
    className += ' ml2';
  }

  let Component = component || ButtonComponent;
  if (rest.to) {
    Component = LinkComponent;
    className += ' link';
  }
  if (rest.href) {
    Component = AnchorComponent;
    className += ' link';
  }

  let lb = label;
  let nslb = null;
  if (notSmallLabel) {
    lb = label ? <span className="di di-m dn-l">{label}</span> : null;
    nslb = <span className="dn dn-m di-l">{notSmallLabel}</span>;
  }

  return (
    <Component
      className={classnames(
        block ? 'db w-100 mw-100' : 'dib',
        large ? 'pa3' : 'pv2 ph2 ph3-l',
        login ? 'b' : null,
        'bn br1 pointer',
        className,
        originalClassName
      )}
      activestyle={activestyle}
      onClick={(e) => handleOnClick(rest.href, rest.download, convert, rest.data, rest.target, e)}
      {...rest}
    >
      {icon && typeof icon === 'string' ? (
        <i className={classnames(icon, { mr2: lb, 'mr2-l': !lb && nslb })} />
      ) : // <i className={classnames(`fa fa-${icon}`, { mr2: lb, 'mr2-l': !lb && nslb })} />
      null}
      {icon && typeof icon !== 'string' ? icon : null}
      {lb}
      {nslb}
    </Component>
  );
}
