import classnames from 'classnames';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme, isSecondary }) =>
    isSecondary ? theme.inputFieldSecondary.background : theme.inputField.background};
  padding: 0.3rem;
  box-shadow: ${({ theme, isSecondary }) =>
    isSecondary ? theme.mainPageDiv.boxShadow : theme.div.boxShadow} !important;
  & > label {
    color: ${({ theme, isSecondary }) =>
      isSecondary ? theme.inputFieldSecondary.color : theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
`;

const WrapperNoShadow = styled.div`
  background: ${({ theme, isSecondary }) =>
    isSecondary ? theme.inputFieldSecondary.background : theme.inputField.background};
  padding: 0.3rem;
  & > label {
    color: ${({ theme, isSecondary }) =>
      isSecondary ? theme.inputFieldSecondary.color : theme.inputField.color};
    font-size: 0.8rem;
    padding: 0.2rem 0;
  }
`;

const InputField = styled.input`
  color: ${({ theme, isSecondary }) =>
    isSecondary ? theme.inputFieldSecondary.color : theme.inputField.color};
  border: ${({ theme, isSecondary }) =>
    isSecondary ? 'none' : theme.selectField.border} !important;
  border-radius: 0.2rem;
  background: none;
  padding: 0.2rem 0;
`;

export default function TextField(props) {
  const { className, meta, label, input, no_shadow = false } = props;
  const hasError = meta.touched && meta.error;

  if (no_shadow) {
    return (
      <WrapperNoShadow isSecondary={props.isSecondary} className="mb2">
        {label ? (
          <label className={classnames('db mb1 fw5', { 'ssf-red': hasError })} htmlFor={props.id}>
            {label}
          </label>
        ) : null}
        <InputField
          isSecondary={props.isSecondary}
          type={props.type || 'text'}
          className={classnames('input-reset db br2 w-100 mb1', className, {
            'b--ssf-red': hasError,
          })}
          id={props.id}
          autoFocus={props.autoFocus}
          autoComplete={props.autoComplete}
          maxLength={props.maxLength}
          inputMode={props.inputMode}
          pattern={props.pattern}
          {...input}
          {...props.extraProps}
        />
        {hasError && <span className="ssf-red">{props.meta.error}</span>}
      </WrapperNoShadow>
    );
  }

  return (
    <Wrapper isSecondary={props.isSecondary} className="mb2">
      {label ? (
        <label className={classnames('db mb1 fw5', { 'ssf-red': hasError })} htmlFor={props.id}>
          {label}
        </label>
      ) : null}
      <InputField
        isSecondary={props.isSecondary}
        type={props.type || 'text'}
        className={classnames('input-reset db br2 w-100 mb1', className, {
          'b--ssf-red': hasError,
        })}
        id={props.id}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        maxLength={props.maxLength}
        inputMode={props.inputMode}
        pattern={props.pattern}
        {...input}
        {...props.extraProps}
      />
      {hasError && <span className="ssf-red">{props.meta.error}</span>}
    </Wrapper>
  );
}
