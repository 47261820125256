import { listMachine } from './machine';
import { listDashboard } from './dashboard';
import { getEntities } from './entity';
// import { getCurrentConfigState } from '../utils/entity';

// eslint-disable-next-line import/prefer-default-export
export function loadInitialDashboardData() {
  return Promise.resolve(listDashboard()).then((response) => {
    if (!response.results) {
      return Promise.reject(new Error('no data in response'));
    }

    return {
      dashboardList: [...response.results],
    };
  });
}

export async function loadInitialMachineData() {
  return Promise.resolve(listMachine()).then((response) => {
    if (!response.results) {
      return Promise.reject(new Error('no data in response'));
    }

    const machinesObject = response.results.reduce(
      (res, machine) => ({ ...res, [machine.id]: machine }),
      {}
    );

    // loop machinesObject and find current_state with key supervision_period and value 172800
    // Object.keys(machinesObject).forEach((key) => {
    //   const machine = machinesObject[key];
    //   if (machine.current_state) {
    //     const [, config] = getCurrentConfigState(machine);
    //     if (config && config.supervision_period > 3600) {
    //       console.log(machine.internal_name, config.supervision_period);
    //     }
    //   }
    // });

    return {
      machinesObject,
    };
  });
}

export async function loadInitialEntityData() {
  return Promise.resolve(getEntities()).then((response) => {
    if (!response.results) {
      return Promise.reject(new Error('no data in response'));
    }

    const entitiesObject = response.results.reduce(
      (res, entity) => ({ ...res, [entity.id]: entity }),
      {}
    );

    return {
      entitiesObject,
    };
  });
}
