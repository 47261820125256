import Loadable from '@docusaurus/react-loadable';

import Loading from './Loading';
import Error from './Error';

function LoadableComponent({ isLoading, error, pastDelay }) {
  if (isLoading) {
    return pastDelay ? <Loading /> : <div />; // Don't flash "Loading..." when we don't need to.
  }
  if (error) {
    return <Error error={error} />;
  }
  return <div />;
}

export default (opts) =>
  Loadable({
    loading: LoadableComponent,
    ...opts,
  });
