import { connect } from 'react-redux';
import styled from 'styled-components';
import { defineMessages, injectIntl } from 'react-intl';

import Button from '../../../components/common/Button';
import { withHOCSRouter } from '../../../components/hocs';

const messages = defineMessages({
  RESET_TIME_RANGE: {
    id: 'TimeRangeSelector.RESET_TIME_RANGE',
    defaultMessage: 'Reset time range to dashboard settings',
  },
  RESET_BTN: {
    id: 'TimeRangeSelector.RESET_BTN',
    defaultMessage: 'Reset',
  },
});

const ButtonReset = styled(Button)`
  @media (max-width: 1125px) {
    & > i {
      margin: 0;
    }
    & > span {
      display: none;
    }
  }
`;

function TimeRangeResetBtn(props) {
  const {
    dashboard,
    from,
    to,
    intl: { formatMessage },
  } = props;
  if (!dashboard) {
    return null;
  }

  if (!dashboard.date_from || !dashboard.date_to) {
    const DEFAULT_FROM = 'now/d';
    const DEFAULT_TO = 'now/d+1d';
    if (DEFAULT_FROM === from && DEFAULT_TO === to) {
      return null;
    }
  } else if (dashboard.date_from === from && dashboard.date_to === to) {
    return null;
  }

  return (
    <ButtonReset
      title={formatMessage(messages.RESET_TIME_RANGE)}
      icon="fas fa-history"
      onClick={() => props.navigate(props.location.pathname)}
      marginLeft
    />
  );
}

export default connect((state) => state.search)(withHOCSRouter(injectIntl(TimeRangeResetBtn)));
